import React, { forwardRef } from 'react'
import { Layout, Header } from '../../Components/Layout/Layout'
import Helpers from '../../Components/Helpers/Helpers'
import Dropdown from 'react-bootstrap/Dropdown'
import { useSelector } from 'react-redux'

const CustomToggle = forwardRef(({ children, onClick }, ref) => {

  const filePhotoUser = useSelector(store => store.session.file_photo_user)

  return (
    <Header.Right.Icon ref={ref} onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      <Header.Right.Image src={Helpers.config.resourcesUrl({ url: filePhotoUser })} />
      {children}
    </Header.Right.Icon>
  )
})

const AppLayout = ({ title, children, page, showBread, hideTop }) => {
  const fullName = useSelector(store => store.session.full_name)
  const permissions = useSelector(store => store.session.permissions)
  const type = useSelector(store => store.session.type)

  const handleLogout = () => {
    localStorage.clear()
    window.location.href = Helpers.config.convertUrl({ url: '/' + (parseInt(type) === 1 ? 'login/clientes' : '') });
  }

  const Logo = Helpers.config.resourcesUrl({ url: '/images/dashboard/logo.png' })

  return <Layout
    resizeNav
    imageLogo={Logo}
    permissions={permissions}
    title={title}
    page={page}
    companyName={Helpers.config.company.name}
    folderPath={Helpers.config.convertUrl({ url: '/' })}
    iconsHeader={[
      <>
        <div className="align-self-center text-white d-none d-sm-block">
          {fullName}
        </div>
        <Dropdown>
          <Dropdown.Toggle variant="success" as={CustomToggle} />
          <Dropdown.Menu>
            <Dropdown.Item className="d-flex" onClick={handleLogout}>
              <i className="fa fa-arrow-left mr-2 align-self-center"></i>
              <span>Salir</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>,
    ]}
    showBread={showBread}
    hideTop={hideTop}
  >
    {children}
  </Layout>
}

AppLayout.defaultProps = {
  title: '',
  page: ''
}

export default AppLayout