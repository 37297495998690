import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import Helpers from '../../Components/Helpers/Helpers';
import { toast } from 'react-toastify'
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';

const CustomersNew = _ => {
    const [isProcessing, setIsProccesing] = useState(true)
    const [rows, setRows] = useState([])

    useEffect(() => {
        setIsProccesing(true)
        Controllers.customers.get_customers_new().then(res => {
            setRows(res.data)
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setIsProccesing(false)
        })
    }, [])

    const dtRows = useMemo(() => <ReactDataTableBootstrap
        head={[
            [
                { name: 'id_customers', text: 'ID', align: 'center' },
                { name: 'full_name_customers', text: 'Nombres', align: 'center' },
                { name: 'name_price', text: 'Tipo', align: 'center' },
                { name: 'date_registration', text: 'Fecha Registro', align: 'center' },
                { name: 'name_country', text: 'País', align: 'center' },
            ],
        ]}
        rows={rows}
        pageLength={100}
        isProcessing={isProcessing}
    />, [rows, isProcessing])

    return <>
        <Card>
            <Card.Header className="justify-content-between">
                <span>Clientes Nuevos</span>
            </Card.Header>
            <Card.Body>
                { dtRows }
            </Card.Body>
        </Card>
    </>
}

export default CustomersNew