import React, { useState } from 'react'
import { Input, FormCustom } from '../../Components/Form/Form'

const BankForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_bank: data.id_bank,
        p_name_bank: data.name_bank
    })

    return <FormCustom dataSubmit={row} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
        <Input
            text="Descripción"
            placeholder="Descripción"
            classNameParent="col-12 mb-2"
            icon="far fa-comment-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.name_bank}
            onChange={e => setRow({ ...row, p_name_bank: e.currentTarget.value })}
            maxLength={150}
        />
    </FormCustom>
}

BankForm.defaultProps = {
    data: {
        id_bank: '',
        name_bank: ''
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default BankForm;