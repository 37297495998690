import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import CountryForm from './CountryForm'
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
import SweetAlert from '../../Components/SweetAlert/SweetAlert'

const Country = ({ nameSingle, namePlural, separator }) => {
    const [isProcessing, setIsProccesing] = useState(true)
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [reload, setReload] = useState(true);
    const [rows, setRows] = useState([])
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [dataUpdated, setDataUpdated] = useState({})
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [loadSubmitDelete, setLoadSubmitDelete] = useState(false)
    const [dataDeleted, setDataDeleted] = useState({})

    useEffect(() => {
        if (reload) {
            setIsProccesing(true)
            Controllers.country.get_country().then(res => {
                setRows(res.data)
            }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setIsProccesing(false)
                setReload(false)
            })
        }
    }, [reload])

    const handleInsertUpdate = ({ data }) => {
        setLoadSubmit(true)
        Controllers.country.country_insert_update(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setModalShow(false)
                setDataUpdated({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setLoadSubmit(false)
            setReload(true)
        })
    }

    const handleGet = e => {
        let $this = e.currentTarget

        if (!$this.dataset?.idCountry) {
            setModalTitle(`Registrar ${nameSingle}`)
            setDataUpdated({})
            setModalShow(true);
            return
        }

        let buttonHTML = $this.innerHTML;
        $this.innerHTML = '<i class="fa fa-circle-notch fa-spin"></i>'
        let { idCountry } = $this.dataset

        Controllers.country.get_country_id({ p_id_country: idCountry }).then(res => {
            setModalTitle(`Modificar ${nameSingle} #${idCountry}`)
            setDataUpdated({ data: res.data })
            setModalShow(true);
        }).catch(req => {
            Helpers.promise.catch({ req, toast })
            setReload(true)
        })
        .finally(() => $this.innerHTML = buttonHTML)
    }

    const handleDelete = () => {
        setLoadSubmitDelete(true)

        Controllers.country.country_delete(dataDeleted).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setShowConfirmDelete(false)
                setDataDeleted({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setReload(true)
            setLoadSubmitDelete(false)
        })
    }

    const handleHideModalForm = () => {
        setModalShow(false)
        setLoadSubmit(false)
    }

    const handleShowModalDelete = e => {
        let { idCountry, nameCountry } = e.currentTarget.dataset
        setDataDeleted({
            p_id_country: idCountry,
            p_name_country: nameCountry
        })
        setShowConfirmDelete(true)
    }

    const handleHideModalDelete = () => setShowConfirmDelete(false)

    const dtRows = useMemo(() => <ReactDataTableBootstrap
        head={[
            [
                { name: 'id_country', text: 'ID', align: 'center' },
                { name: 'name_country', text: 'Descripción' },
                { name: 'code_country', text: 'Código', align: 'center' },
                { name: 'actions', text: <i className="fa fa-cog"></i>, align: 'center', render: u => {
                    return <div>
                        <Button variant="info" className="mr-1" size="xs" data-id-country={u.id_country} onClick={handleGet}>
                            <i className="fa fa-edit"></i>
                        </Button>
                        <Button variant="danger" size="xs" data-id-country={u.id_country} data-name-country={u.name_country} onClick={handleShowModalDelete}>
                            <i className="fa fa-trash"></i>
                        </Button>
                    </div>
                } },
            ],
        ]}
        rows={rows}
        isProcessing={isProcessing}
    />, [rows, isProcessing])

    return <>
        <Card>
            <Card.Header className="justify-content-between">
                <span>Listado De {namePlural}</span>
                <Button size="sm" onClick={handleGet}>
                    <i className="fa fa-plus mr-1"></i> Registrar {nameSingle}
                </Button>
            </Card.Header>
            <Card.Body>
                { dtRows }
            </Card.Body>
        </Card>
        <Modal show={modalShow} onHide={handleHideModalForm} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title as="div">{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <CountryForm onSubmit={handleInsertUpdate} loadSubmit={loadSubmit} {...dataUpdated} />
            </Modal.Body>
        </Modal>
        <SweetAlert
            icon="delete"
            show={showConfirmDelete}
            showCancelButton
            title={<>¿Desea eliminar {separator} {nameSingle}?</>}
            subtitle={<>#{dataDeleted.p_id_country} - {dataDeleted.p_name_country}</>}
            confirmButtonText="¡Sí, eliminar!"
            cancelButtonText="¡No!"
            loadSubmit={loadSubmitDelete}
            onCancel={handleHideModalDelete}
            onConfirm={handleDelete}
        />
    </>
}

export default Country