import React, { useCallback, useEffect, useMemo } from 'react'
import { useState } from 'react'
import Button from 'react-bootstrap/esm/Button'
import Card from 'react-bootstrap/esm/Card'
import ReactPlaceholder from 'react-placeholder/lib'
import Controllers from '../../Api/Controllers'
import { FormCustom, Input, Select } from '../../Components/Form/Form'
import { toast } from 'react-toastify'
import Helpers from '../../Components/Helpers/Helpers'

const PersonalInformation = () => {
    const [ready, setReady] = useState(false)
    const [readyOptions, setReadyOptions] = useState(false)
    const [user, setUser] = useState({})
    const [showPassword, setShowPassword] = useState(false)
    const [rowsBank, setRowsBank] = useState([])
    const [rowsTypeFormatPay, setRowsTypeFormatPay] = useState([])
    const [rowsLanguage, setRowsLanguage] = useState([])
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [photoUser, setPhotoUser] = useState('')

    const dataSubmit = useCallback(_ => {
        let formData = new FormData()

        for (const key in user) {
            if (key === 'file_photo_customers') {
                if (user.file_photo_customers?.files) {
                    if (user.file_photo_customers.files.length > 0) {
                        formData.append('p_file_photo_customers', user.file_photo_customers.files[0])
                        continue
                    }
                }
                formData.append('p_file_photo_customers', '')
                continue
            }

            if (key === 'social') {
                let dataSocial = []

                if (user?.social) {
                    dataSocial = user.social.map(s => {
                        return {
                            p_id_customers_social: s.id_customers_social,
                            p_type_social: s.type_social,
                            p_url_social: s.url_social
                        }
                    })
                }
                formData.append('p_social', JSON.stringify(dataSocial))
                continue
            }

            formData.append('p_' + key, user[key])
        }
        return formData
        /* return {
            p_id_customers: user.id_customers,
            p_password_user: user.password_user,
            p_id_language: user.id_language,
            p_id_bank: user.id_bank,
            p_number_account_bank: user.number_account_bank,
            p_id_type_format_pay: user.id_type_format_pay,
            p_id_customers_bank: user.id_customers_bank,
            p_social: user?.social ? user.social.map(s => {
                return {
                    p_id_customers_social: s.id_customers_social,
                    p_type_social: s.type_social,
                    p_url_social: s.url_social
                }
            }) : []
        } */
    }, [user])

    const typeFomatPaySelected = useMemo(() => {
        if (!user.id_type_format_pay) {
            return
        }

        let finded = rowsTypeFormatPay.find(t => parseInt(user.id_type_format_pay) === parseInt(t.id_type_format_pay))

        if (!finded) {
            return
        }
        return {
            label: finded.name_format_pay,
            value: finded.id_type_format_pay
        }
    }, [user.id_type_format_pay, rowsTypeFormatPay])

    const bankSelected = useMemo(() => {
        if (!user.id_bank) {
            return
        }

        let finded = rowsBank.find(t => parseInt(user.id_bank) === parseInt(t.id_bank))

        if (!finded) {
            return
        }
        return {
            label: finded.name_bank,
            value: finded.id_bank
        }
    }, [user.id_bank, rowsBank])

    const language = useMemo(() => {
        if (!user.id_language) {
            return
        }

        let finded = rowsLanguage.find(t => parseInt(user.id_language) === parseInt(t.id_language))

        if (!finded) {
            return
        }
        return {
            label: finded.name_language,
            value: finded.id_language
        }
    }, [user.id_language, rowsLanguage])

    useEffect(() => {
        if (!ready) {
            Controllers.users.get_personal_information().then(res => {
                setUser(res.data)
                setPhotoUser(res.data.file_photo_customers)
                setReady(true)
            })
        }
    }, [ready])

    useEffect(() => {
        Promise.all([
            Controllers.language.get_language(),
            Controllers.bank.get_bank(),
            Controllers.type_format_pay.get_type_format_pay()
        ]).then(res => {
            setRowsLanguage(res[0].data)
            setRowsBank(res[1].data)
            setRowsTypeFormatPay(res[2].data)
            setReadyOptions(true)
        })
    }, [])

    return <div className="form-row">
        <div className="col-md-6 col-12">
            <Card>
                <Card.Header>
                    Información Personal
                </Card.Header>
                <Card.Body>
                    <ReactPlaceholder ready={ready && readyOptions} showLoadingAnimation rows={10}>
                        <FormCustom loadSubmit={loadSubmit} dataSubmit={dataSubmit()} onSubmit={({ data }) => {
                            if (user.number_account_bank && (!user.id_bank || !user.id_type_format_pay)) {
                                Helpers.toast.construct({ response: 'warning', message: 'Por favor, completar información bancaria', toast })
                                return 
                            }
                            
                            setLoadSubmit(true)
                            Controllers.customers.customers_information_update(data).then(res => {
                                Helpers.toast.construct({ ...res, toast })
                                setLoadSubmit(false)
                                setReady(false)
                            })
                        }}>
                            <Input
                                text="Nombres"
                                disabled
                                defaultValue={user.name_customers}
                                classNameParent="col-6 mb-2"
                                icon="fa fa-user"
                            />
                            <Input
                                text="Apellidos"
                                disabled
                                defaultValue={user.last_name_customers}
                                classNameParent="col-6 mb-2"
                                icon="fa fa-user"
                            />
                            <Input
                                icon="fa fa-image"
                                type="file"
                                text="Imagen"
                                classNameParent="col-12 mb-2"
                                accept="image/png, image/jpe"
                                onChange={e => setUser({ ...user, file_photo_customers: e.currentTarget })}
                            />
                            {photoUser ? <div className="col-12">
                                <a href={Helpers.config.resourcesUrl({ url: photoUser })} target="_blank">
                                    <img src={Helpers.config.resourcesUrl({ url: photoUser })} width="300" className="img-thumbnail" />
                                </a>
                            </div> : ''}
                            <Input
                                text="Teléfono"
                                classNameParent="col-6 mb-2"
                                icon="fa fa-phone"
                                disabled
                                defaultValue={user.phone_customers}
                            />
                            <Input
                                text="Email"
                                type="email"
                                classNameParent="col-6 mb-2"
                                icon="fa fa-envelope"
                                disabled
                                defaultValue={user.email_customers}
                            />
                            <Input
                                text="Dirección"
                                disabled
                                defaultValue={user.address_customers}
                                classNameParent="col-12 mb-2"
                                icon="fas fa-map-marker-alt"
                            />
                            <Input
                                type="date"
                                text="Fecha Nacimiento"
                                classNameParent="col-6 mb-2"
                                icon="far fa-calendar-alt"
                                disabled
                                defaultValue={user.date_birthday_customers}
                            />
                            <Input
                                text="Sucursal"
                                classNameParent="col-6 mb-2"
                                icon="far fa-building"
                                disabled
                                defaultValue={user.name_branch_office}
                            />
                            <Select
                                classNameParent="col-6 mb-2"
                                icon="fas fa-language"
                                text="Idioma"
                                options={rowsLanguage.map(t => {
                                    return {
                                        label: t.name_language,
                                        value: t.id_language
                                    }
                                })}
                                value={language}
                                onChange={e => setUser({ ...user, id_language: e.value })}
                            />
                            <div className="col-12 mt-2 d-flex flex-column">
                                <small>Información Bancaria</small>
                                <small className="text-warning">{parseInt(user.status_bank) === 1 ? <i>Ya actualizó su información bancaria anteriormente.</i> : ''}</small>
                            </div>
                            <Select
                                classNameParent="col-12 mb-2"
                                icon="fas fa-university"
                                text="Banco"
                                options={rowsBank.map(t => {
                                    return {
                                        label: t.name_bank,
                                        value: t.id_bank
                                    }
                                })}
                                value={bankSelected}
                                onChange={e => {
                                    if (parseInt(user.status_bank) !== 1) {
                                        setUser({ ...user, id_bank: e.value })
                                    }
                                }}
                                disabled={parseInt(user.status_bank) === 1}
                            />
                            <Input
                                text="Nro Cuenta Bancaria"
                                placeholder="Nro Cuenta Bancaria"
                                classNameParent="col-6 mb-2"
                                icon="fas fa-sort-numeric-up"
                                invalid="El campo es obligatorio."
                                defaultValue={user.number_account_bank}
                                required={user.id_bank !== '' || user.id_type_format_pay !== ''}
                                onChange={e => {
                                    if (parseInt(user.status_bank) !== 1) {
                                        setUser({ ...user, number_account_bank: e.currentTarget.value })
                                    }
                                }}
                                maxLength={100}
                                disabled={parseInt(user.status_bank) === 1}
                            />
                            <Select
                                classNameParent="col-6 mb-2"
                                icon="fas fa-file-invoice-dollar"
                                text="Formato De Pago"
                                options={rowsTypeFormatPay.map(t => {
                                    return {
                                        label: t.name_format_pay,
                                        value: t.id_type_format_pay
                                    }
                                })}
                                value={typeFomatPaySelected}
                                onChange={e => {
                                    if (parseInt(user.status_bank) !== 1) {
                                        setUser({ ...user, id_type_format_pay: e.value })
                                    }
                                }}
                                disabled={parseInt(user.status_bank) === 1}
                            />
                            <div className="col-12 mt-2">
                                <small>Información Redes Sociales</small>
                            </div>
                            {user?.social ? React.Children.toArray(user.social.map(s => {
                                return <>
                                    <Input
                                        text={Helpers.string.toCamelCase(s.type_social.toLowerCase())}
                                        placeholder={Helpers.string.toCamelCase(s.type_social.toLowerCase())}
                                        classNameParent="col-12 mb-2"
                                        icon="far fa-comment-alt"
                                        value={s.url_social}
                                        disabled={parseInt(s.status_social) === 1}
                                        onChange={e => setUser({ ...user, social: user.social.map(ss => {
                                            if (ss.type_social === s.type_social) {
                                                return {
                                                    ...ss,
                                                    url_social: e.currentTarget.value
                                                }
                                            }

                                            return ss
                                        }) })}
                                        maxLength={100}
                                    />
                                </>
                            })) : ''}
                            <div className="col-12 mt-2">
                                <small>Acceso al Sistema</small>
                            </div>
                            <Input
                                text="Usuario"
                                disabled
                                defaultValue={user.id_customers}
                                classNameParent="col-12 mb-2"
                                className="text-center"
                            />
                            <Input
                                text="Contraseña"
                                type={showPassword ? 'text' : 'password'}
                                defaultValue={user.password_user}
                                classNameParent="col-12 mb-2"
                                append={[
                                    <Button variant="info" onMouseEnter={_ => setShowPassword(true)} onMouseLeave={_ => setShowPassword(false)}>
                                        <i className="fa fa-eye"></i>
                                    </Button>
                                ]}
                                onChange={e => setUser({ ...user, password_user: e.currentTarget.value })}
                            />
                        </FormCustom>
                    </ReactPlaceholder>
                </Card.Body>
            </Card>
        </div>
    </div>
}

export default PersonalInformation
