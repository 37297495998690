import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import Helpers from '../../Components/Helpers/Helpers';
import { toast } from 'react-toastify'

const CustomersList = _ => {
    const [isProcessing, setIsProccesing] = useState(true)
    const [rows, setRows] = useState([])

    useEffect(() => {
        setIsProccesing(true)
        Controllers.customers.get_customers().then(res => {
            setRows(res.data)
            console.log(res.data)
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setIsProccesing(false)
        })
    }, [])

    const dtRows = useMemo(() => <ReactDataTableBootstrap
        head={[
            [
                { name: 'id_customers', text: 'ID', align: 'center' },
                { name: 'name_branch_office', text: 'Sucursal', align: 'center' },
                { name: 'name_customers', text: 'Nombres', align: 'center', render: r => {
                    return <span>
                        {r.name_customers} {r.last_name_customers} {r.last_name_mot_customers}
                    </span>
                } },
                { name: 'email_customers', text: 'Email', align: 'center' },
                { name: 'phone_customers', text: 'Telefono', align: 'center' },
                { name: 'name_country', text: 'País', align: 'center' },
            ],
        ]}
        rows={rows}
        pageLength={100}
        isProcessing={isProcessing}
    />, [rows, isProcessing])

    return <>
        <Card>
            <Card.Header className="justify-content-between">
                <span>Clientes Nuevos</span>
            </Card.Header>
            <Card.Body>
                { dtRows }
            </Card.Body>
        </Card>
    </>
}

export default CustomersList