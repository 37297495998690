import React, { useState, useEffect, useCallback } from 'react'
import { FormCustom, Input, Select, SwitchToggle } from '../../Components/Form/Form'
import Button from 'react-bootstrap/Button'
import Controllers from '../../Api/Controllers'
import Helpers from '../../Components/Helpers/Helpers'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import ReactPlaceholder from 'react-placeholder'

const CustomersEcommerceForm = ({ customers, reloadCustomers, cancelCustomers }) => {
    const [isCustomersOk, setIsCustomersOk] = useState(true)
    const [isCustomersSearch, setIsCustomersSearch] = useState(true)
    const [row, setRow] = useState({ })
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [customersAddress, setCustomersAddress] = useState([])
    const [ready, setReady] = useState(false)

    useEffect(() => {
        setReady(false)
        Controllers.customers_address.get_customers_address({
            p_id_customers: customers.id_customers
        }).then(res => {
            setIsCustomersOk(true)
            setIsCustomersSearch(true)
            setRow({
                p_id_customers: customers.id_customers,
                p_id_customers_sale: customers.id_customers_sale,
                p_full_name_customers: customers.full_name_customers,
                p_address_customers: customers.address_customers,
                p_zip_code_customers: customers.zip_code_customers,
                p_province_customers: customers.province_customers,
                p_department_customers: customers.department_customers,
                p_is_customers_sale: customers.is_customers_sale,
                p_id_customers_address: customers.id_customers_address,
                p_send_my_sponsor: customers.send_my_sponsor
            })


            setCustomersAddress([
                {
                    id_customers_address: '',
                    address_customers: customers.address_customers,
                    zip_code_customers: customers.zip_code_customers,
                    province_customers: customers.province_customers,
                    department_customers: customers.department_customers,
                },
                ...res.data,
            ])
            setReady(true)
        })
    }, [customers])

    const addressSelected = useCallback(() => {
        if (isNaN(parseInt(row.p_id_customers_address))) {
            return {
                value: '',
                label: `${row.p_address_customers} - ${row.p_province_customers}/${row.p_department_customers} - ${row.p_zip_code_customers}`
            }
        }

        let custFinded = customersAddress.find(a => parseInt(a.id_customers_address) === row.p_id_customers_address)
        
        if (!custFinded) {
            return {
                value: '',
                label: `${row.p_address_customers} - ${row.p_province_customers}/${row.p_department_customers} - ${row.p_zip_code_customers}`
            }
        }

        return {
            value: '',
            label: `${custFinded.address_customers} - ${custFinded.province_customers}/${custFinded.department_customers} - ${custFinded.zip_code_customers}`
        }
    }, [ row.p_id_customers_address, customersAddress ])

    return <ReactPlaceholder ready={ready} showLoadingAnimation rows={15}>
        <FormCustom loadSubmit={loadSubmit} dataSubmit={row} disablebSubmit={!isCustomersOk || !isCustomersSearch} onSubmit={_ => {
        setLoadSubmit(true)
            Controllers.cart.cart_update({
                p_id_customers_sale: row.p_id_customers_sale,
                p_id_customers_address: row.p_id_customers_address,
                p_send_my_sponsor: row.p_send_my_sponsor
            }).then(_ => {
                setLoadSubmit(false)
                reloadCustomers()
            })
        }}>
            <div className="col-12">
                <div className="d-flex justify-content-between">
                    <small className="align-self-center">Si cambia de cliente el carrito se compra se reiniciará.</small>
                    <i className="fa fa-times text-secondary fa-2x cur-pointer" onClick={cancelCustomers}></i>
                </div>
            </div>
            <Input
                type="number"
                text={<>ID {!isCustomersSearch && !isCustomersOk ? '' : (!isCustomersOk ? <>
                    <i className="fa fa-times text-danger"></i>
                    <span className="text-danger"> No Pertenece a tu RED</span></> : '')}
                </>}
                placeholder="ID"
                classNameParent="col-12 mb-2"
                value={row.p_id_customers_sale ? row.p_id_customers_sale : ''}
                min="1"
                step="1"
                append={[
                    <Button type="button" variant="primary" disabled={isCustomersSearch} size="sm" onClick={_ => {
                        if (!row.p_id_customers_sale) {
                            return
                        }

                        Controllers.customers.get_search_my_refer({
                            p_id_customers: row.p_id_customers_sale
                        }).then(res => {
                            if (res.response !== 'success') {
                                setIsCustomersOk(false)
                                setIsCustomersSearch(true)
                                setRow({ 
                                    ...row,
                                    p_full_name_customers: '',
                                    p_address_customers: ''
                                })
                                return
                            }

                            if (parseInt(res.data.is_customers_sale) === 1) {
                                Controllers.customers_address.get_customers_address({
                                    p_id_customers: customers.id_customers
                                }).then(res2 => {
                                    setCustomersAddress([
                                        {
                                            id_customers_address: '',
                                            address_customers: res.data.address_customers,
                                            zip_code_customers: res.data.zip_code_customers,
                                            province_customers: res.data.province_customers,
                                            department_customers: res.data.department_customers,
                                        },
                                        ...res2.data,
                                    ])
                                })
                            }
                            setRow({
                                ...row,
                                p_id_customers_sale: res.data.id_customers,
                                p_full_name_customers: res.data.full_name_customers,
                                p_address_customers: res.data.address_customers,
                                p_zip_code_customers: res.data.zip_code_customers,
                                p_province_customers: res.data.province_customers,
                                p_department_customers: res.data.department_customers,
                                p_is_customers_sale: res.data.is_customers_sale,
                                p_send_my_sponsor: 0
                            })
                            setIsCustomersOk(true)
                            setIsCustomersSearch(true)
                        })
                    }}>
                        <i className="fa fa-search"></i> Buscar
                    </Button>
                ]}
                onChange={e => {
                    setIsCustomersSearch(false)
                    setRow({ ...row, p_id_customers_sale: e.currentTarget.value })
                }}
            />
            <Input
                text="Nombres"
                placeholder="Nombres"
                disabled
                classNameParent="col-12 mb-2"
                value={row.p_full_name_customers ? row.p_full_name_customers : ''}
            />
            {parseInt(row.p_is_customers_sale) === 1 ? <>
                <Select
                    options={customersAddress.map(a => {
                        return {
                            value: a.id_customers_address,
                            label: `${a.address_customers} - ${a.province_customers}/${a.department_customers} - ${a.zip_code_customers}`
                        }
                    })}
                    text="Dirección"
                    onChange={e => setRow({ ...row, p_id_customers_address: e.value })}
                    value={addressSelected()}
                />
            </> : <>
                <SwitchToggle
                    id="send_my_sponsor"
                    textCheck="Enviar a mi domicilio"
                    textUnChecked="Enviar a domicilio de referido"
                    defaultChecked={parseInt(customers.send_my_sponsor) === 1}
                    onChange={e => setRow({ ...row, p_send_my_sponsor: e.currentTarget.checked ? 1 : 0 })}
                />
                {parseInt(row.p_send_my_sponsor) === 0 ? <Input
                    text="Dirección"
                    placeholder="Dirección"
                    disabled
                    value={row.p_address_customers ? row.p_address_customers : ''}
                    classNameParent="col-12 mb-2"
                /> : ''}
            </>}
        </FormCustom>
    </ReactPlaceholder>
}

export default CustomersEcommerceForm