import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ReactPlaceholder from 'react-placeholder';
import Controllers from '../../Api/Controllers';
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';

const FilePublic = () => {
    const [ready, setReady] = useState(true)
    const [rows, setRows] = useState([])

    useEffect(() => {
        Controllers.file.get_file_public().then(res => {
            setRows(res.data)
            setReady(true)
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setReady(true)
        })
    }, [])

    const existsFile = useCallback(() => {
        let exists = false
        for (const key in rows) {
            if (rows[key].length > 0) {
                exists = true
                break
            }
        }
        
        return exists
    }, [rows])

    const renderFile = type => {
        return <div className="mb-4">
            <h6>Archivos {Helpers.string.toCamelCase(type)}</h6>
            <div className="form-row">
                {React.Children.toArray(rows[type].map(d => {
                    let img = d.image
                    let col = '2'

                    if (type === 'imagen') {
                        img = d.path_file
                        col = '4'
                    }

                    let content = <>
                        <a href={d.path_file} target="_blank" className="d-flex flex-column text-decoration-none">
                            <img src={img} className="img-fluid" />
                            <span className="text-center mt-2">{d.name_file}</span>
                        </a>
                    </>

                    if (type === 'youtube') {
                        content = <div className="text-center">
                            <div class="embed-responsive embed-responsive-4by3 mb-2">
                                <iframe class="embed-responsive-item" src={d.path_file}></iframe>
                            </div>
                            <span>{d.name_file}</span>
                        </div>
                        col = 6
                    }

                    return <div className={'col-6 col-md-' + col}>
                        <Card>
                            <Card.Body>
                                {content}
                            </Card.Body>
                        </Card>
                    </div>
                }))}
            </div>
        </div>
    }

    return <ReactPlaceholder ready={ready} rows={10} showLoadingAnimation>
        {existsFile() ? <>
            {rows?.drive?.length > 0 ? renderFile('drive') : ''}
            {rows?.excel?.length > 0 ? renderFile('excel') : ''}
            {rows?.imagen?.length > 0 ? renderFile('imagen') : ''}
            {rows?.pdf?.length > 0 ? renderFile('pdf') : ''}
            {rows?.word?.length > 0 ? renderFile('word') : ''}
            {rows?.youtube?.length > 0 ? renderFile('youtube') : ''}
            {rows?.otros?.length > 0 ? renderFile('otros') : ''}</> : <Card>
                <Card.Body>
                    <div className="alert alert-warning">
                        ¡Opps! No hay archivos para mostrar.
                    </div>
                </Card.Body>
            </Card>}
    </ReactPlaceholder>
}

export default FilePublic