import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import Helpers from '../../Components/Helpers/Helpers';
import { toast } from 'react-toastify'
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';
import { useSelector } from 'react-redux';

const HistorySale = _ => {
    const [isProcessing, setIsProccesing] = useState(true)
    const [rows, setRows] = useState([])
    const [idDocumentPdf, setIdDocumentPdf] = useState('')
    const [modalShowPdf, setModalShowPdf] = useState(false);
    const jwt = useSelector(store => store.session.jwt)

    useEffect(() => {
        setIsProccesing(true)
        Controllers.document.get_customers_sale().then(res => {
            setRows(res.data)
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setIsProccesing(false)
        })
    }, [])

    const dtRows = useMemo(() => <ReactDataTableBootstrap
        head={[
            [
                { name: 'id_document', text: 'ID', align: 'center' },
                { name: 'date_doc', text: 'Fecha', align: 'center' },
                { name: 'qty_total', text: 'Cantidad', align: 'center' },
                { name: 'total_doc', text: 'Total', align: 'center' },
                { name: 'point_total', text: 'Puntos', align: 'center' },
                { name: 'value_business_total', text: 'Valor Negocio', align: 'center' },
                { name: 'key_branch_office', text: 'Sucursal', align: 'center' },
                { name: 'source_doc', text: 'Tipo Venta', align: 'center', render: r => {
                    let text = 'VENTA'
                    let color = 'success'

                    switch (r.source_doc) {
                        case 'ECOMMERCE':
                            text = 'ECOMMERCE'
                            color = 'dark'
                            break;
                        case 'PENDIENTE':
                            text = 'PENDIENTE'
                            color = 'warning'
                            break;
                        case 'PAGADO':
                            text = 'PAGADO'
                            color = 'info'
                            break;
                    
                        default:
                            break;
                    }

                    return <div className={'badge badge-' + (color)}>
                        {text}
                    </div>
                } },
                { name: 'actions', text: <i className="fa fa-cog"></i>, align: 'center', render: u => <Button variant="danger" className="ml-1" size="xs" onClick={_ => {
                    setIdDocumentPdf(u.id_document)
                    setModalShowPdf(true)
                }}>
                    <i className="fa fa-file-pdf"></i>
                </Button> }
            ],
        ]}
        rows={rows}
        pageLength={100}
        isProcessing={isProcessing}
    />, [rows, isProcessing])

    return <>
        <Card>
            <Card.Header className="justify-content-between">
                <span>Historial de Compras</span>
            </Card.Header>
            <Card.Body>
                { dtRows }
            </Card.Body>
        </Card>
        <Modal show={modalShowPdf} onHide={_ => setModalShowPdf(false)} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title as="div">Documento de la Venta</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <iframe className="w-100" style={{ 
                    minHeight: 500
                 }} src={Helpers.config.apiUrl({ url: '/sale/generate/invoice/' + idDocumentPdf + '?jwt=' + jwt })}></iframe>
            </Modal.Body>
        </Modal>
    </>
}

export default HistorySale