import React, { useEffect, useMemo, useState } from 'react'
import Button from 'react-bootstrap/esm/Button'
import ReactPlaceholder from 'react-placeholder/lib'
import Controllers from '../../Api/Controllers'
import { Input, FormCustom, Select, SwitchToggle } from '../../Components/Form/Form'

const WorkerForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_worker: data.id_worker,
        p_name_worker: data.name_worker,
        p_last_name_worker: data.last_name_worker,
        p_id_profile: data.id_profile,
        p_enabled_worker: data.enabled_worker,
        p_email_worker: data.email_worker,
        p_username_user: data.username_user,
        p_password_user: data.password_user,
        p_id_profile: data.id_profile,
        p_id_user: data.id_user,
    })

    const [ready, setReady] = useState(false)
    const [rowsProfile, setRowsProfile] = useState([])
    const [viewPassword, setViewPassword] = useState(false)

    useEffect(() => {
        Controllers.profile.get_profile().then(res => {
            setRowsProfile(res.data)
            setReady(true)
        })
    }, [])

    const idProfile = useMemo(() => {
        if (!row.p_id_profile) {
            return
        }

        let finded = rowsProfile.find(t => parseInt(row.p_id_profile) === parseInt(t.id_profile))

        if (!finded) {
            return
        }
        return {
            label: finded.description_profile,
            value: finded.id_branch_office
        }
    }, [row.p_id_profile, rowsProfile])

    return <ReactPlaceholder ready={ready} showLoadingAnimation rows={15}>
        <FormCustom dataSubmit={row} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
            <Input
                text="Nombres"
                placeholder="Nombres"
                classNameParent="col-12 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.name_worker}
                onChange={e => setRow({ ...row, p_name_worker: e.currentTarget.value })}
                maxLength={150}
            />
            <Input
                text="Apellidos"
                placeholder="Apellidos"
                classNameParent="col-12 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.last_name_worker}
                onChange={e => setRow({ ...row, p_last_name_worker: e.currentTarget.value })}
                maxLength={150}
            />
            <Input
                text="Email"
                type="email"
                placeholder="Email"
                classNameParent="col-12 mb-2"
                icon="fa fa-envelope"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.email_worker}
                onChange={e => setRow({ ...row, p_email_worker: e.currentTarget.value })}
                maxLength={150}
            />
            <SwitchToggle
                classNameParent="col-12 mb-2"
                id="worker-enabled"
                defaultChecked={parseInt(row.p_enabled_worker) === 1}
                onChange={e => setRow({ ...row, p_enabled_worker: e.currentTarget.checked ? 1 : 0 })}
            />
            <div className="col-12">
                <small>Información de Sistema</small>
            </div>
            <Input
                text="Nombre de usuario"
                placeholder="Nombre de usuario"
                classNameParent="col-12 mb-2"
                icon="far fa-comment-alt"
                pattern="[a-zA-Z]{1,30}"
                required
                invalid="El nombre de usuario es incorrecto."
                defaultValue={data.username_user}
                onChange={e => setRow({ ...row, p_username_user: e.currentTarget.value })}
                maxLength={30}
            />
            <Input
                text="Contraseña"
                placeholder="Contraseña"
                type={viewPassword ? 'text' : 'password'}
                classNameParent="col-12 mb-2"
                icon="far fa-comment-alt"
                required
                defaultValue={data.password_user}
                onChange={e => setRow({ ...row, p_password_user: e.currentTarget.value })}
                maxLength={100}
                append={[
                    <Button variant="info" onMouseEnter={_ => setViewPassword(true)} onMouseLeave={_ => setViewPassword(false)}>
                        <i className="fa fa-eye"></i>
                    </Button>
                ]}
            />
            <Select
                classNameParent="col-12 mb-2"
                icon="fas fa-user-tie"
                text="Perfil"
                options={rowsProfile.map(t => {
                    return {
                        label: t.description_profile,
                        value: t.id_profile
                    }
                })}
                value={idProfile}
                onChange={e => setRow({ ...row, p_id_profile: e.value })}
            />
        </FormCustom>
    </ReactPlaceholder>
}

WorkerForm.defaultProps = {
    data: {
        id_worker: '',
        name_worker: '',
        last_name_worker: '',
        id_profile: '',
        enabled_worker: '0',
        email_worker: '',
        username_user: '',
        password_user: '',
        id_profile: '',
        id_user: '',
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default WorkerForm;