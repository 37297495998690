import React, { useState } from 'react'
import { Input, FormCustom } from '../../Components/Form/Form'

const TypeRoundForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_type_round: data.id_type_round,
        p_name_round: data.name_round
    })

    return <FormCustom dataSubmit={row} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
        <Input
            text="Descripción"
            placeholder="Descripción"
            classNameParent="col-12 mb-2"
            icon="far fa-comment-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.name_round}
            onChange={e => setRow({ ...row, p_name_round: e.currentTarget.value })}
            maxLength={150}
        />

    </FormCustom>
}

TypeRoundForm.defaultProps = {
    data: {
        id_type_round: '',
        name_round: ''
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default TypeRoundForm;