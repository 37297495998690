import React from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button'
import { useCallback } from 'react'

const FormCustom = ({ onSubmit, dataSubmit, children, viewSubmit, disablebSubmit, textButton, className, loadSubmit }) => {
  const handleSubmit = useCallback(e => {
    e.preventDefault()
    let dataForm = {}

    let hasError = false
    let f = e.target.elements
    for (let i = 0; i < f.length; i++) {
      let ele = f[i]

      /* if (!ele.name) {
        continue
      } */

      if (!ele.validity.valid) {
        ele.focus()
        hasError = true
        break
      }

      if (ele.tagName === 'INPUT') {
        if (ele.name.includes('[]')) {
          if (ele.checked) {
            if (!dataForm[ele.name.replace('[]', '')]) {
              dataForm[ele.name.replace('[]', '')] = [ele.value]
            } else {
              dataForm[ele.name.replace('[]', '')].push(ele.value)
            }
          }
          continue
        }
        
        if (ele.value !== ele.value.trim()) {
          ele.focus()
          hasError = true
          break
        }
      
        if (!dataForm[ele.name]) {
          dataForm[ele.name] = ele.value
        }
      }
      else if (ele.tagName === 'TEXTAREA') {
        if (ele.value !== ele.value.trim()) {
          ele.focus()
          hasError = true
          break
        }
        if (!dataForm[ele.name]) {
          dataForm[ele.name] = ele.value
        }
      }
      else if (ele.tagName === 'SELECT') {
        if (!dataForm[ele.name]) {
          dataForm[ele.name] = ele.value
        }
      }
    }

    if (!e.target.checkValidity() || hasError) {
      e.target.classList.add('was-validated')
      return
    }
    
    e.target.classList.remove('was-validated')
    
    onSubmit({ e, data: dataSubmit })
  }, [onSubmit, dataSubmit])

  return(<form className={'needs-validation form-row ' + className} noValidate onSubmit={handleSubmit} autoComplete="off">
    {children}
    {viewSubmit ? <div className="col-12">
      <Button variant="success" disabled={disablebSubmit || loadSubmit} className="mt-2" size="sm" block type="submit">
        {loadSubmit ? <i className="fa fa-circle-notch fa-spin"></i> : <><i className="fa fa-check mr-2"></i> {textButton}</>}
      </Button>
    </div> : ''}
  </form>)
}
FormCustom.defaultProps = {
  onSubmit: () => {},
  viewSubmit: true,
  data: [],
  disablebSubmit: false,
  textButton: 'Guardar Información',
  className: '',
  loadSubmit: false
}
FormCustom.propTypes = {
  onSubmit: PropTypes.func,
  viewSubmit: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  disablebSubmit: PropTypes.bool,
  loadSubmit: PropTypes.bool,
}

export default FormCustom