import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import Helpers from '../../Components/Helpers/Helpers';
import { toast } from 'react-toastify'
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';
import ReactPlaceholder from 'react-placeholder/lib';

const CustomersPoint = _ => {
    const [ready, setReady] = useState(false)
    const [rows, setRows] = useState([])

    useEffect(() => {
        Promise.all([
            Controllers.report.get_report_point_customers(),
        ]).then(res => {
            setRows(res[0].data)
        }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setReady(true)
            })
    }, [])
    return <>
        <Card className="mb-4">
            <Card.Header className="justify-content-between">
                <span>Reporte de Puntos de Clientes</span>
            </Card.Header>
            <Card.Body>
                <ReactPlaceholder ready={ready} showLoadingAnimation rows={15}>
                    <div className="table-responsive">
                        <table className="table table-sm table-hover table-striped table-bordered">
                            <thead>
                                <tr>
                                    {React.Children.toArray(rows?.head?.map((h, i) => {
                                        let th = {}
                                        if (i > 2) {
                                            th.colSpan = 2
                                        } else {
                                            th.rowSpan = 2
                                        }
                                        return <th {...th}>{h}</th>
                                    }))}
                                </tr>
                                <tr>
                                    {React.Children.toArray(rows?.head?.filter((h, i) => {
                                        return i > 2
                                    }).map(_ => {
                                        return <>
                                            <th>Puntos</th>
                                            <th>Valor Negocio</th>
                                        </>
                                    }))}
                                </tr>
                            </thead>
                            <tbody>
                                {React.Children.toArray(rows?.body?.map(h => {
                                    let td = []
                                    for (let i = 0; i < h.length; i++) {
                                        td.push(<td align="center">
                                            {i > 2 ? 
                                            (parseInt(h[i]) > 0 ? <strong>{h[i]}</strong> : 0)
                                            : h[i]}
                                        </td>)
                                    }

                                    return <tr>
                                        {React.Children.toArray(td)}
                                    </tr>
                                }))}
                            </tbody>
                        </table>
                    </div>
                </ReactPlaceholder>
            </Card.Body>
        </Card>
    </>
}

export default CustomersPoint