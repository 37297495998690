import styled from 'styled-components'
import { boxShadow, bgPrimaryLight } from '../Resources/Theme'

const Loading = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgb(108 162 193 / 50%);
  overflow: hidden;
  -webkit-transition: .5s;
  transition: .5s;
  box-shadow: ${boxShadow};
  -webkit-transition: .5s;
  transition: .5s;
  z-index: 9999999;
  opacity: .5;
  display: ${props => props.show ? 'flex' : 'none'};
  justify-content: center;
  transition: .5s
`

Loading.Img = styled.img`
  width: 100px
`
/* End Brand */
export default Loading