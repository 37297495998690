import React, { useState } from 'react'
import { Input, FormCustom } from '../../Components/Form/Form'

const CustomersAddressForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_customers_address: data.id_customers_address,
        p_zip_code_customers: data.zip_code_customers,
        p_department_customers: data.department_customers,
        p_province_customers: data.province_customers,
        p_address_customers: data.address_customers,
    })

    return <FormCustom dataSubmit={row} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
        <Input
            text="Código Postal"
            placeholder="Código Postal"
            classNameParent="col-6 mb-2"
            icon="fas fa-map-pin"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.zip_code_customers}
            onChange={e => setRow({ ...row, p_zip_code_customers: e.currentTarget.value })}
            maxLength={20}
        />
        <Input
            text="Estado"
            placeholder="Estado"
            classNameParent="col-6 mb-2"
            icon="fas fa-map-marker-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.department_customers}
            onChange={e => setRow({ ...row, p_department_customers: e.currentTarget.value })}
            maxLength={100}
        />
        <Input
            text="Ciudad"
            placeholder="Ciudad"
            classNameParent="col-6 mb-2"
            icon="fas fa-map-marker-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.province_customers}
            onChange={e => setRow({ ...row, p_province_customers: e.currentTarget.value })}
            maxLength={100}
        />
        <Input
            text="Dirección"
            placeholder="Dirección"
            classNameParent="col-6 mb-2"
            icon="fas fa-map-marker-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.address_customers}
            onChange={e => setRow({ ...row, p_address_customers: e.currentTarget.value })}
            maxLength={150}
        />
        
    </FormCustom>
}

CustomersAddressForm.defaultProps = {
    data: {
        id_customers_address: '',
        zip_code_customers: '',
        department_customers: '',
        province_customers: '',
        address_customers: ''
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default CustomersAddressForm;