import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Controllers from '../../Api/Controllers'
import { Input, FormCustom, Select } from '../../Components/Form/Form'
import ReactPlaceholder from 'react-placeholder'
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';

const BranchOfficeForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => { 

    const [row, setRow] = useState({
        p_id_branch_office: data.id_branch_office,
        p_key_branch_office: data.key_branch_office,
        p_name_branch_office: data.name_branch_office,
        p_start_ticket_branch_office: data.start_ticket_branch_office,
        p_end_ticket_branch_office: data.end_ticket_branch_office,
        p_id_tax_1: data.id_tax_1,
        p_id_tax_2: data.id_tax_2,
        p_id_tax_3: data.id_tax_3,
        p_id_type_money: data.id_type_money,
        p_id_type_round: data.id_type_round,
        p_id_branch_office_stock: data.id_branch_office_stock,
        p_shipping_free: data.shipping_free,
        p_shipping_value: data.shipping_value,
    })

    const dataSubmit = useCallback(() => {
        return {
            ...row, 
            p_tax: [
                { p_id_tax: row.p_id_tax_1, p_order_tax: 1 },
                { p_id_tax: row.p_id_tax_2, p_order_tax: 2 },
                { p_id_tax: row.p_id_tax_3, p_order_tax: 3 }
            ]
        }
    }, [row])

    const [ready, setReady] = useState(false)
    const [rowsTypeMoney, setRowsTypeMoney] = useState([])
    const [rowsTypeRound, setRowsTypeRound] = useState([])
    const [rowsBranchOfficeStock, setRowsBranchOfficeStock] = useState([])
    const [rowsTax, setRowsTax] = useState([])

    const typeMoney = useMemo(() => {
        if (!row.p_id_type_money) {
            return
        }

        let finded = rowsTypeMoney.find(t => parseInt(row.p_id_type_money) === parseInt(data.id_type_money))

        if (!finded) {
            return
        }
        return {
            label: finded.name_money,
            value: finded.id_type_money
        }
    }, [row.p_id_type_money, rowsTypeMoney])

    const typeRound = useMemo(() => {
        if (!row.p_id_type_round) {
            return
        }

        let finded = rowsTypeRound.find(t => parseInt(t.id_type_round) === parseInt(row.p_id_type_round))

        if (!finded) {
            return
        }

        return {
            label: finded.name_round,
            value: finded.id_type_round
        }
    }, [row.p_id_type_round, rowsTypeRound])

    const branchOfficeStock = useMemo(() => {
        if (!row.p_id_branch_office_stock) {
            return
        }

        let finded = rowsBranchOfficeStock.find(t => parseInt(t.id_branch_office) === parseInt(row.p_id_branch_office_stock))

        if (!finded) {
            return
        }

        return {
            label: finded.name_branch_office,
            value: finded.id_branch_office
        }
    }, [row.p_id_branch_office_stock, rowsBranchOfficeStock])

    const tax_1 = useMemo(() => {
        if (!row.p_id_tax_1) {
            return
        }

        let finded = rowsTax.find(t => parseInt(t.id_tax) === parseInt(row.p_id_tax_1))

        if (!finded) {
            return
        }

        return {
            label: `${finded.name_tax} - ${finded.value_tax}`,
            value: finded.id_tax
        }
    }, [row.p_id_tax_1, rowsTax])

    const tax_2 = useMemo(() => {
        if (!row.p_id_tax_2) {
            return
        }

        let finded = rowsTax.find(t => parseInt(t.id_tax) === parseInt(row.p_id_tax_2))

        if (!finded) {
            return
        }

        return {
            label: `${finded.name_tax} - ${finded.value_tax}`,
            value: finded.id_tax
        }
    }, [row.p_id_tax_2, rowsTax])

    const tax_3 = useMemo(() => {
        if (!row.p_id_tax_3) {
            return
        }

        let finded = rowsTax.find(t => parseInt(t.id_tax) === parseInt(row.p_id_tax_3))

        if (!finded) {
            return
        }

        return {
            label: `${finded.name_tax} - ${finded.value_tax}`,
            value: finded.id_tax
        }
    }, [row.p_id_tax_3, rowsTax])

    useEffect(() => {
        let promises = [
            Controllers.type_money.get_type_money(),
            Controllers.type_round.get_type_round(),
            Controllers.branch_office.get_branch_office(),
            Controllers.tax.get_tax()
        ]
        
        Promise.all(promises).then(res => {
            setRowsTypeMoney(res[0].data)
            setRowsTypeRound(res[1].data)
            setRowsBranchOfficeStock(res[2].data)
            setRowsTax(res[3].data)
            setReady(true)
        })
    }, [])

    return <ReactPlaceholder rows={7} ready={ready} showLoadingAnimation>
        <FormCustom dataSubmit={dataSubmit()} onSubmit={({ data }) => {
            if (!row.p_id_type_money) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar el tipo de moneda', toast })
                return 
            }

            if (!row.p_id_type_round) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar el tipo de redondeo', toast })
                return 
            }

            if (!row.p_id_tax_1 /* || !row.p_id_tax_2 || !row.p_id_tax_3 */) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar el impuesto 1.', toast })
                return 
            }

            /* if ((parseInt(row.p_id_tax_1) === parseInt(row.p_id_tax_2)) || (parseInt(row.p_id_tax_1) === parseInt(row.p_id_tax_3)) || (parseInt(row.p_id_tax_2) === parseInt(row.p_id_tax_3))) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar diferentes impuestos', toast })
                return 
            } */

            onSubmit({ data })
        }} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
            <Input
                text="Clave*"
                placeholder="Clave"
                classNameParent="col-6 mb-2"
                icon="fas fa-code"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.key_branch_office}
                onChange={e => setRow({ ...row, p_key_branch_office: e.currentTarget.value })}
                maxLength={20}
            />
            <Input
                text="Descripción"
                placeholder="Descripción"
                classNameParent="col-6 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="Error en formato. Ej: MX, USA"
                defaultValue={data.name_branch_office}
                onChange={e => setRow({ ...row, p_name_branch_office: e.currentTarget.value })}
                maxLength={150}
            />
            <Input
                text="Inicio Ticket"
                placeholder="Inicio Ticket"
                classNameParent="col-6 mb-2"
                icon="fas fa-sort-numeric-up"
                required
                invalid="Campo obligatorio"
                defaultValue={data.start_ticket_branch_office}
                onChange={e => setRow({ ...row, p_start_ticket_branch_office: e.currentTarget.value })}
                maxLength={20}
            />
            <Input
                text="Fin Ticket"
                placeholder="Fin Ticket"
                classNameParent="col-6 mb-2"
                icon="fas fa-sort-numeric-up"
                required
                invalid="Campo obligatorio"
                defaultValue={data.end_ticket_branch_office}
                onChange={e => setRow({ ...row, p_end_ticket_branch_office: e.currentTarget.value })}
                maxLength={20}
            />
            <Select
                classNameParent="col-6 mb-2"
                icon="fas fa-dollar-sign"
                text="Tipo Moneda"
                options={rowsTypeMoney.map(t => {
                    return {
                        label: t.name_money,
                        value: t.id_type_money
                    }
                })}
                value={typeMoney}
                onChange={e => setRow({ ...row, p_id_type_money: e.value })}
            />
            <Select
                classNameParent="col-6 mb-2"
                icon="fas fa-divide"
                text="Tipo Redondeo"
                options={rowsTypeRound.map(t => {
                    return {
                        label: t.name_round,
                        value: t.id_type_round
                    }
                })}
                value={typeRound}
                onChange={e => setRow({ ...row, p_id_type_round: e.value })}
            />
            <Select
                classNameParent="col-6 mb-2"
                icon="far fa-building"
                text="Sucursal Descuento Stock"
                options={rowsBranchOfficeStock.map(t => {
                    return {
                        label: t.name_branch_office,
                        value: t.id_branch_office
                    }
                })}
                value={branchOfficeStock}
                onChange={e => setRow({ ...row, p_id_branch_office_stock: e.value })}
            />
            <Select
                classNameParent="col-6 mb-2"
                icon="fas fa-percentage"
                text="Impuesto 1"
                options={rowsTax.map(t => {
                    return {
                        label: `${t.name_tax} - ${t.value_tax}`,
                        value: t.id_tax
                    }
                })}
                value={tax_1}
                onChange={e => setRow({ ...row, p_id_tax_1: e.value })}
            />
            <Select
                classNameParent="col-6 mb-2"
                icon="fas fa-percentage"
                text="Impuesto 2"
                options={rowsTax.map(t => {
                    return {
                        label: `${t.name_tax} - ${t.value_tax}`,
                        value: t.id_tax
                    }
                })}
                value={tax_2}
                onChange={e => setRow({ ...row, p_id_tax_2: e.value })}
            />
            <Select
                classNameParent="col-6 mb-2"
                icon="fas fa-percentage"
                text="Impuesto 3"
                options={rowsTax.map(t => {
                    return {
                        label: `${t.name_tax} - ${t.value_tax}`,
                        value: t.id_tax
                    }
                })}
                value={tax_3}
                onChange={e => setRow({ ...row, p_id_tax_3: e.value })}
            />
            <Input
                text="Shipping Gratis Desde"
                placeholder="Shipping Gratis Desde"
                classNameParent="col-6 mb-2"
                icon="fas fa-sort-numeric-up"
                required
                invalid="Campo obligatorio"
                defaultValue={data.shipping_free}
                onChange={e => setRow({ ...row, p_shipping_free: e.currentTarget.value })}
                type="number"
                min="0"
                step="0.01"
            />
            <Input
                text="Shipping Costo"
                placeholder="Shipping Costo"
                classNameParent="col-6 mb-2"
                icon="fas fa-sort-numeric-up"
                required
                invalid="Campo obligatorio"
                defaultValue={data.shipping_value}
                onChange={e => setRow({ ...row, p_shipping_value: e.currentTarget.value })}
                type="number"
                min="0"
                step="0.01"
            />
        </FormCustom>
    </ReactPlaceholder>
}

BranchOfficeForm.defaultProps = {
    data: {
        id_branch_office: '',
        key_branch_office: '',
        name_branch_office: '',
        id_type_money: '',
        id_type_round: '',
        id_branch_office_stock: '',
        start_ticket_branch_office: '',
        end_ticket_branch_office: '',
        id_tax_1: '',
        id_tax_2: '',
        id_tax_3: '',
        shipping_free: '',
        shipping_value: ''
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default BranchOfficeForm;