import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import ProductForm from './ProductForm'
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
import SweetAlert from '../../Components/SweetAlert/SweetAlert'
import ProductComponent from '../ProductComponent/ProductComponent';
import ProductPrice from '../ProductPrice/ProductPrice';
import ReactPlaceholder from 'react-placeholder/lib';
import { FormCustom, Input, Select } from '../../Components/Form/Form';

const Product = ({ nameSingle, namePlural, separator }) => {
    const [isProcessing, setIsProccesing] = useState(true)
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [reload, setReload] = useState(true);
    const [rows, setRows] = useState([])
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [dataUpdated, setDataUpdated] = useState({})
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [loadSubmitDelete, setLoadSubmitDelete] = useState(false)
    const [dataDeleted, setDataDeleted] = useState({})

    // Para configurar componentes de productos
    const [modalShowComponent, setModalShowComponent] = useState(false);
    const [modalTitleComponent, setModalTitleComponent] = useState('');
    const [dataComponent, setDataComponent] = useState({})

    // Para configurar precios de productos
    const [modalShowPrice, setModalShowPrice] = useState(false);
    const [modalTitlePrice, setModalTitlePrice] = useState('');
    const [dataPrice, setDataPrice] = useState({})
    const [ready, setReady] = useState(false)

    const [rowsBranchOffice, setRowsBranchOffice] = useState([])
    const [dataFilter, setDataFilter] = useState({
        p_id_customers: '',
        p_id_sponsor: '',
        p_id_upline: '',
        p_name_customers: '',
        p_last_name_customers: '',
        p_curp_customers: '',
        p_id_branch_office: ''
    })

    const branchOffice = useMemo(() => {
        if (!dataFilter.p_id_branch_office) {
            return {
                label: 'Todas',
                value: ''
            }
        }

        let finded = rowsBranchOffice.find(t => parseInt(dataFilter.p_id_branch_office) === parseInt(t.id_branch_office))

        if (!finded) {
            return
        }

        return {
            label: `${finded.key_branch_office} - ${finded.name_branch_office}`,
            value: finded.id_branch_office
        }
    }, [dataFilter.p_id_branch_office, rowsBranchOffice])

    useEffect(() => {
        Controllers.branch_office.get_branch_office().then(res => {
            setRowsBranchOffice([ { name_branch_office: 'Todas', id_branch_office: '' }, ...res.data ])
            setReady(true)
        })
    }, [])

    useEffect(() => {
        if (reload) {
            setIsProccesing(true)
            Controllers.product.get_product().then(res => {
                setRows(res.data)
            }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setIsProccesing(false)
                setReload(false)
            })
        }
    }, [reload])

    const handleInsertUpdate = ({ data }) => {
        setLoadSubmit(true)
        Controllers.product.product_insert_update(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setModalShow(false)
                setDataUpdated({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setLoadSubmit(false)
            setReload(true)
        })
    }

    const handleGet = e => {
        let $this = e.currentTarget

        if (!$this.dataset?.idProduct) {
            setModalTitle(`Registrar ${nameSingle}`)
            setDataUpdated({})
            setModalShow(true);
            return
        }

        let buttonHTML = $this.innerHTML;
        $this.innerHTML = '<i class="fa fa-circle-notch fa-spin"></i>'
        let { idProduct } = $this.dataset

        Controllers.product.get_product_id({ p_id_product: idProduct }).then(res => {
            setModalTitle(`Modificar ${nameSingle} #${idProduct}`)
            setDataUpdated({
                data: res.data
            })
            setModalShow(true);
        }).catch(req => {
            Helpers.promise.catch({ req, toast })
            setReload(true)
        })
        .finally(() => $this.innerHTML = buttonHTML)
    }

    const handleDelete = () => {
        setLoadSubmitDelete(true)

        Controllers.product.product_delete(dataDeleted).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setShowConfirmDelete(false)
                setDataDeleted({})
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setReload(true)
            setLoadSubmitDelete(false)
        })
    }

    const handleHideModalForm = () => {
        setModalShow(false)
        setLoadSubmit(false)
    }

    const handleShowModalDelete = e => {
        let { idProduct, nameProduct } = e.currentTarget.dataset
        setDataDeleted({
            p_id_product: idProduct,
            p_name_product: nameProduct
        })
        setShowConfirmDelete(true)
    }

    const handleShowModalComponent = e => {
        let { idProduct } = e.currentTarget.dataset
        setModalTitleComponent('Agregar Componentes a Producto #' + idProduct)
        setDataComponent({
            idProduct
        })
        setModalShowComponent(true)
    }

    const handleShowModalPrice = e => {
        let { idProduct } = e.currentTarget.dataset
        setModalTitlePrice('Listado de Precios del Producto #' + idProduct)
        setDataPrice({
            idProduct
        })
        setModalShowPrice(true)
    }

    const handleHideModalComponent = _ => {
        setModalShowComponent(false)
    }

    const handleHideModalPrice = _ => {
        setModalShowPrice(false)
    }

    const handleHideModalDelete = () => setShowConfirmDelete(false)

    const dtRows = useMemo(() => <ReactDataTableBootstrap
        head={[
            [
                { name: 'key_product', text: 'Clave', align: 'center' },
                { name: 'key_branch_office', text: 'Sucursal', align: 'center' },
                { name: 'name_product', text: 'Nombre' },
                { name: 'pack_product', text: 'Paquete', render: r => {
                    return <div className={`badge badge-${parseInt(r.pack_product) === 1 ? 'success' : 'secondary'}`}>
                        {parseInt(r.pack_product) === 1 ? 'SI' : 'NO'}
                    </div>
                }, align: 'center' },
                { name: 'name_clasification', text: 'Clasificación', align: 'center' },
                { name: 'exists_current_qty_product', text: 'Existencia Actual', align: 'center' },
                { name: 'exists_separated_qty_product', text: 'Existencia Apartada', align: 'center' },
                { name: 'file_photo', text: 'Foto', render: r => {
                    return r.file_photo ? <a href={Helpers.config.resourcesUrl({ url: r.file_photo })} target="_blank">
                    <img src={Helpers.config.resourcesUrl({ url: r.file_photo })} width="100" />
                </a> : <div className="badge badge-warning">
                    Sin Foto
                </div>
                }, align: 'center' },
                /* { name: 'iva_product', text: 'IVA', align: 'center' }, */
                { name: 'tax_1', text: 'Tax', align: 'center' },
                { name: 'actions', text: <i className="fa fa-cog"></i>, align: 'center', render: u => {
                    return <div>
                        <Button variant="info" className="mr-1" size="xs" data-id-product={u.id_product} onClick={handleGet}>
                            <i className="fa fa-edit"></i>
                        </Button>
                        <Button variant="danger" className="mr-1" size="xs" data-id-product={u.id_product} data-name-product={u.name_product} onClick={handleShowModalDelete}>
                            <i className="fa fa-trash"></i>
                        </Button>
                        {parseInt(u.pack_product) === 1 ?<Button variant="secondary" size="xs" className="mr-1" data-id-product={u.id_product} onClick={handleShowModalComponent}>
                            <i className="fa fa-list"></i>
                        </Button> : ''}
                        <Button variant="success" size="xs" data-id-product={u.id_product} onClick={handleShowModalPrice}>
                            <i className="fas fa-dollar-sign"></i>
                        </Button>
                    </div>
                } },
            ],
        ]}
        rows={rows}
        isProcessing={isProcessing}
    />, [rows, isProcessing])

    return <>
        <Card>
            <Card.Header className="justify-content-between">
                <span>Listado De {namePlural}</span>
                <Button size="sm" onClick={handleGet}>
                    <i className="fa fa-plus mr-1"></i> Registrar {nameSingle}
                </Button>
            </Card.Header>
            <Card.Body>
                <ReactPlaceholder ready={ready} rows={15} showLoadingAnimation>
                    {/* <FormCustom viewSubmit={false} className="mb-3">
                        <Input
                            classNameParent="col-6 col-md-4 mb-2"
                            icon="far fa-user"
                            placeholder="Clave"
                            text="Clave"
                            onChange={e => setDataFilter({ ...dataFilter, p_key_product: e.currentTarget.value })}
                        />
                        <Select
                            classNameParent="col-6 col-md-4 mb-2"
                            icon="far fa-building"
                            text="Sucursal"
                            options={rowsBranchOffice.map(t => {
                                return {
                                    label: `${t.key_branch_office} - ${t.name_branch_office}`,
                                    value: t.id_branch_office
                                }
                            })}
                            value={branchOffice}
                            onChange={e => setDataFilter({ ...dataFilter, p_id_branch_office: e.value })}
                        />
                        <Input
                            classNameParent="col-12 col-md-4 mb-2"
                            icon="fas fa-sort-numeric-up"
                            placeholder="Nombre" text="Nombre"
                            append={[<Button>
                                <i className="fa fa-search mr-2"></i>Buscar
                            </Button>]}
                            onChange={e => setDataFilter({ ...dataFilter, p_name_product: e.currentTarget.value })}
                        />
                    </FormCustom> */}

                    { dtRows }
                </ReactPlaceholder>
            </Card.Body>
        </Card>
        <Modal show={modalShow} onHide={handleHideModalForm} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title as="div">{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ProductForm onSubmit={handleInsertUpdate} loadSubmit={loadSubmit} {...dataUpdated} />
            </Modal.Body>
        </Modal>
        <Modal show={modalShowComponent} onHide={handleHideModalComponent} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title as="div">{modalTitleComponent}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ProductComponent {...dataComponent} nameSingle={Helpers.string.toCamelCase('Componente')} namePlural={Helpers.string.toCamelCase('Componentes')} separator={'el'.toLowerCase()} />
            </Modal.Body>
        </Modal>
        <Modal show={modalShowPrice} onHide={handleHideModalPrice} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title as="div">{modalTitlePrice}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ProductPrice {...dataPrice} nameSingle={Helpers.string.toCamelCase('Precio')} namePlural={Helpers.string.toCamelCase('Precios')} separator={'el'.toLowerCase()} onSuccess={handleHideModalPrice} />
            </Modal.Body>
        </Modal>
        <SweetAlert
            icon="delete"
            show={showConfirmDelete}
            showCancelButton
            title={<>¿Desea eliminar {separator} {nameSingle}?</>}
            subtitle={<>#{dataDeleted.p_id_product} - {dataDeleted.p_name_product}</>}
            confirmButtonText="¡Sí, eliminar!"
            cancelButtonText="¡No!"
            loadSubmit={loadSubmitDelete}
            onCancel={handleHideModalDelete}
            onConfirm={handleDelete}
        />
    </>
}

export default Product