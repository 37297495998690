import React, { useCallback, useEffect, useState } from 'react'
import ReactPlaceholder from 'react-placeholder/lib'
import Controllers from '../../Api/Controllers'
import { Input, FormCustom, SwitchToggle, Select } from '../../Components/Form/Form'

const PromoForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_promo: data.id_promo,
        p_name_promo: data.name_promo,
        p_flag_point_promo: data.flag_point_promo,
        p_percentage_discount_promo: data.percentage_discount_promo,
        p_flag_percentage_discount_promo: data.flag_percentage_discount_promo,
        p_date_start_promo: data.date_start_promo,
        p_date_end_promo: data.date_end_promo,
        p_enabled_promo: data.enabled_promo,
        p_products: data.products
    })

    const [ready, setReady] = useState(false)
    const [rowsProducts, setRowsProducts] = useState([])

    useEffect(() => {
        Controllers.product.get_product().then(res => {
            setRowsProducts(res.data)
            setReady(true)
        })
    }, [])

    const dataSubmit = useCallback(() => {
        return {
            ...row,
            p_products: row.p_products.map(p => {
                return {
                    p_id_product: p.id_product,
                    p_qty_min: p.qty_min,
                    p_qty_free: p.qty_free,
                }
            })
        }
    }, [row])

    return <ReactPlaceholder ready={ready} showLoadingAnimation rows={15}>
        <FormCustom dataSubmit={dataSubmit()} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
            <Input
                text="Descripción"
                placeholder="Descripción"
                classNameParent="col-12 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.name_promo}
                onChange={e => setRow({ ...row, p_name_promo: e.currentTarget.value })}
                maxLength={150}
            />
            <SwitchToggle
                id="switch-flag_point_promo"
                textCheck="Puntos y Valor Negocio por el Producto Gratis"
                textUnChecked="Puntos y Valor Negocio por el Producto Gratis"
                classNameParent="col-12 mb-2"
                defaultChecked={parseInt(data.flag_point_promo) === 1}
                onChange={e => setRow({ ...row, p_flag_point_promo: e.currentTarget.checked ? 1 : 0 })}
            />
            <Input
                text="Porcentaje de Descuento"
                placeholder="Porcentaje de Descuento"
                classNameParent="col-12 mb-2"
                icon="fa fa-sort-numeric-up"
                required
                type="number"
                min="0"
                step="0.01"
                invalid="El campo es obligatorio."
                defaultValue={data.percentage_discount_promo}
                onChange={e => setRow({ ...row, p_percentage_discount_promo: e.currentTarget.value })}
                maxLength={150}
            />
            <SwitchToggle
                id="switch-flag_percentage_discount_promo"
                textCheck="Descuenta porcentaje a los Puntos y Valor Negocio"
                textUnChecked="Descuenta porcentaje a los Puntos y Valor Negocio"
                classNameParent="col-12 mb-2"
                defaultChecked={parseInt(data.flag_percentage_discount_promo) === 1}
                onChange={e => setRow({ ...row, p_flag_percentage_discount_promo: e.currentTarget.checked ? 1 : 0 })}
            />
            <Input
                text="Fecha Inicio"
                placeholder="Fecha Inicio"
                classNameParent="col-12 mb-2"
                icon="fa fa-calendar-alt"
                required
                type="date"
                invalid="El campo es obligatorio."
                defaultValue={data.date_start_promo}
                onChange={e => setRow({ ...row, p_date_start_promo: e.currentTarget.value })}
                maxLength={150}
            />
            <Input
                text="Fecha Fin"
                placeholder="Fecha Fin"
                classNameParent="col-12 mb-2"
                icon="fa fa-calendar-alt"
                required
                type="date"
                invalid="El campo es obligatorio."
                defaultValue={data.date_end_promo}
                onChange={e => setRow({ ...row, p_date_end_promo: e.currentTarget.value })}
                maxLength={150}
            />
            <SwitchToggle
                id="switch-enabled_promo"
                classNameParent="col-12 mb-2"
                defaultChecked={parseInt(data.enabled_promo) === 1}
                onChange={e => setRow({ ...row, p_enabled_promo: e.currentTarget.checked ? 1 : 0 })}
            />
            <div className="col-12">
                <small>Producto de Promoción</small>
            </div>
            {React.Children.toArray(row.p_products.map(p => {
                let finded = rowsProducts.find(r => parseInt(r.id_product) === parseInt(p.id_product))

                return <>
                    <Select
                        classNameParent="col-12 mb-2"
                        text="Productos"
                        icon="fa fa-boxes"
                        options={rowsProducts.map(r => {
                            return {
                                label: r.name_product,
                                value: r.id_product
                            }
                        })}
                        value={finded ? {
                            label: finded.name_product,
                            value: finded.id_product
                        } : ''}
                        onChange={e => setRow({ ...row, p_products: row.p_products.map(pp => {
                            if (parseInt(pp.id_product) === parseInt(p.id_product)) {
                                pp.id_product = e.value
                            }
                            return pp
                        }) })}
                    />
                    <Input
                        text="Cantidad"
                        placeholder="Cantidad"
                        classNameParent="col-12 mb-2"
                        icon="fa fa-sort-numeric-up"
                        required
                        type="number"
                        min="1"
                        step="1"
                        invalid="El campo es obligatorio."
                        value={p.qty_min}
                        onChange={e => setRow({ ...row, p_products: row.p_products.map(pp => {
                            if (parseInt(pp.id_product) === parseInt(p.id_product)) {
                                pp.qty_min = e.currentTarget.value
                            }
                            return pp
                        }) })}
                    />
                    <Input
                        text="Cuanto Gratis"
                        placeholder="Cuanto Gratis"
                        classNameParent="col-12 mb-2"
                        icon="fa fa-sort-numeric-up"
                        required
                        type="number"
                        min="0"
                        step="1"
                        invalid="El campo es obligatorio."
                        value={p.qty_free}
                        onChange={e => setRow({ ...row, p_products: row.p_products.map(pp => {
                            if (parseInt(pp.id_product) === parseInt(p.id_product)) {
                                pp.qty_free = e.currentTarget.value
                            }
                            return pp
                        }) })}
                    />
                </>
            }))}
        </FormCustom>
    </ReactPlaceholder>
}

PromoForm.defaultProps = {
    data: {
        id_promo: '',
        name_promo: '',
        flag_point_promo: '0',
        percentage_discount_promo: '',
        flag_percentage_discount_promo: '0',
        date_start_promo: '',
        date_end_promo: '',
        enabled_promo: '',
        products: []
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default PromoForm;