import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Card from 'react-bootstrap/Card';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import Helpers from '../../Components/Helpers/Helpers';
import { toast } from 'react-toastify'
import { FormCustom, Input, Select } from '../../Components/Form/Form';
import Button from 'react-bootstrap/Button'
import Loading from '../../Components/Layout/Styled/Loading'

const BreakBoxUser = _ => {
    const [workers, setWorkers] = useState([])
    const [reporte, setReport] = useState({})

    useEffect(() => {
        Controllers.worker.get_worker().then(res => {
            setWorkers(res.data)
        })
    }, [])

    const [data, setData] = useState({
        p_id_worker: '',
        p_date_start: '',
        p_date_end: ''
    })
    const [showLoading, setShowLoading] = useState(false)
    
    const worker = useCallback(() => {
        if (!data.p_id_worker) {
            return ''
        }

        let findWorker = workers.find(w => parseInt(w.id_worker) === parseInt(data.p_id_worker))

        if (!findWorker) {
            return ''
        }

        return {
            label: findWorker.name_worker + ' ' + findWorker.last_name_worker,
            value: findWorker.id_worker
        }
    }, [data.p_id_worker, workers])

    return <>
        <Card>
            <Card.Header className="justify-content-between">
                <span>Corte de Caja por Usuario</span>
            </Card.Header>
            <Card.Body>
                <FormCustom viewSubmit={false} onSubmit={_ => {
                    if (!data.p_id_worker) {
                        Helpers.toast.construct({
                            response: 'warning',
                            message: 'Seleccionar Usuario',
                            toast
                        })
                        return
                    }

                    setShowLoading(true)
                    setReport({})
                    Controllers.report.get_break_box_worker(data).then(res => {
                        if (res.response !== 'success') {
                            Helpers.toast.construct({ ...res, toast })
                            return
                        }

                        setReport({
                            pdf: res.data.pdf.file,
                            excel: res.data.excel.file,
                            filename: res.data.filename,
                        })
                    }).catch(req => Helpers.promise.catch({ req, toast }))
                    .finally(() => {
                        setShowLoading(false)
                    })
                }}>
                    <Select
                        classNameParent="col-12 mb-2"
                        icon="fa fa-user"
                        text="Usuario"
                        required
                        onChange={e => {
                            setReport({})
                            setData({ ...data, p_id_worker: e.value })
                        }}
                        value={worker()}
                        options={workers.map(w => {
                            return {
                                label: w.name_worker + ' ' + w.last_name_worker,
                                value: w.id_worker
                            }
                        })}
                    />
                    <Input
                        classNameParent="col-6 mb-4"
                        type="date"
                        icon="far fa-calendar-alt"
                        placeholder="Fecha Inicio"
                        text="Fecha Inicio"
                        required
                        value={data.p_date_start}
                        onChange={e => {
                            setReport({})
                            setData({ ...data, p_date_start: e.currentTarget.value })
                        }}
                    />
                    <Input
                        classNameParent="col-6 mb-4"
                        type="date"
                        icon="far fa-calendar-alt"
                        placeholder="Fecha Fin"
                        text="Fecha Fin"
                        required
                        value={data.p_date_end}
                        onChange={e => {
                            setReport({})
                            setData({ ...data, p_date_end: e.currentTarget.value })
                        }}
                        append={[
                            <Button type="submit">
                                <i className="fa fa-search"></i>
                            </Button>
                        ]}
                    />
                </FormCustom>
                {reporte?.filename ? <>
                    <a href={reporte?.pdf} className="mr-2" download={reporte?.filename}>
                        <Button size="sm" variant="danger">
                            <i className="fa fa-file-pdf mr-2"></i>Descargar Reporte PDF
                        </Button>
                    </a>
                    <a href={reporte?.excel} download={reporte?.filename}>
                        <Button size="sm" variant="success">
                            <i className="fa fa-file-excel mr-2"></i>Descargar Reporte Excel
                        </Button>
                    </a>
                </> : ''}
            </Card.Body>
        </Card>
        <Loading show={showLoading}>
            <Loading.Img src={Helpers.config.resourcesUrl({ url: '/images/dashboard/preloading.svg' })} alt="Preloading" />
        </Loading>
    </>
}

export default BreakBoxUser