import React, { useState } from 'react'
import { Input, FormCustom } from '../../Components/Form/Form'

const TypeMoneyForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_type_money: data.id_type_money,
        p_name_money: data.name_money,
        p_code_money: data.code_money
    })

    return <FormCustom dataSubmit={row} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
        <Input
            text="Descripción"
            placeholder="Descripción"
            classNameParent="col-12 mb-2"
            icon="far fa-comment-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.name_money}
            onChange={e => setRow({ ...row, p_name_money: e.currentTarget.value })}
            maxLength={150}
        />
        <Input
            text="Código"
            placeholder="Código"
            classNameParent="col-12 mb-2"
            icon="fas fa-code"
            required
            invalid="Error en formato. Ej: USD, MX"
            defaultValue={data.code_money}
            onChange={e => setRow({ ...row, p_code_money: e.currentTarget.value })}
            maxLength={5}
        />
    </FormCustom>
}

TypeMoneyForm.defaultProps = {
    data: {
        id_type_money: '',
        name_money: '',
        code_money: ''
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default TypeMoneyForm;