import React from 'react'
import Button from 'react-bootstrap/esm/Button'
import Card from 'react-bootstrap/esm/Card'
import { useHistory } from 'react-router-dom'
import Helpers from '../../Components/Helpers/Helpers'

const PaymentSuccess = _ => {
    const history = useHistory()

    return <Card>
        <Card.Body className="d-flex align-items-center flex-column pt-5 pb-5">
            <i className="fa fa-check-circle text-success fa-5x mb-4"></i>
            <h1><strong>¡Pago Realizado!</strong></h1>
            <Button onClick={_ => history.push(Helpers.config.convertUrl({ url: '/historial-compras' }))}>
                Ver Historial de Compras
            </Button>
        </Card.Body>
    </Card>
}

export default PaymentSuccess