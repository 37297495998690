import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import Helpers from '../../Components/Helpers/Helpers';
import { toast } from 'react-toastify'
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';
import ReactPlaceholder from 'react-placeholder/lib';

const SaleProductsQtyPrice = _ => {
    const [ready, setReady] = useState(false)
    const [rows, setRows] = useState([])
    const [rowsPrice, setRowsPrice] = useState([])

    useEffect(() => {
        Promise.all([
            Controllers.sale.get_sale_product_qty(),
            Controllers.sale.get_sale_product_price()
        ]).then(res => {
            setRows(res[0].data)
            setRowsPrice(res[1].data)
        }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setReady(true)
            })
    }, [])
    return <>
        <Card className="mb-4">
            <Card.Header className="justify-content-between">
                <span>Ventas Totales por Producto y Cantidad</span>
            </Card.Header>
            <Card.Body>
                <ReactPlaceholder ready={ready} showLoadingAnimation rows={15}>
                    <div className="table-responsive">
                        <table className="table table-sm table-hover table-striped table-bordered">
                            <thead>
                                <tr>
                                    {React.Children.toArray(rows?.head?.map(h => <th>{h}</th>))}
                                </tr>
                            </thead>
                            <tbody>
                                {React.Children.toArray(rows?.body?.map(h => {
                                    let td = []
                                    for (let i = 0; i < h.length; i++) {
                                        td.push(<td align="center">{h[i]}</td>)
                                    }

                                    return <tr>
                                        {React.Children.toArray(td)}
                                    </tr>
                                }))}
                            </tbody>
                        </table>
                    </div>
                </ReactPlaceholder>
            </Card.Body>
        </Card>
        <Card className="mb-4">
            <Card.Header className="justify-content-between">
                <span>Ventas Totales por Producto y Precio</span>
            </Card.Header>
            <Card.Body>
                <ReactPlaceholder ready={ready} showLoadingAnimation rows={15}>
                    <div className="table-responsive">
                        <table className="table table-sm table-hover table-striped table-bordered">
                            <thead>
                                <tr>
                                    {React.Children.toArray(rowsPrice?.head?.map(h => <th>{h}</th>))}
                                </tr>
                            </thead>
                            <tbody>
                                {React.Children.toArray(rowsPrice?.body?.map(h => {
                                    let td = []
                                    for (let i = 0; i < h.length; i++) {
                                        td.push(<td align="center">{h[i]}</td>)
                                    }

                                    return <tr>
                                        {React.Children.toArray(td)}
                                    </tr>
                                }))}
                            </tbody>
                        </table>
                    </div>
                </ReactPlaceholder>
            </Card.Body>
        </Card>
    </>
}

export default SaleProductsQtyPrice