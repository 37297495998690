import React, { useEffect, useMemo, useState } from 'react'
import ReactPlaceholder from 'react-placeholder/lib'
import Controllers from '../../Api/Controllers'
import { Input, FormCustom, Select } from '../../Components/Form/Form'
import Helpers from '../../Components/Helpers/Helpers'
import { toast } from 'react-toastify'

const ExchangeForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_exchange: data.id_exchange,
        p_date_exchange: data.date_exchange,
        p_id_type_money_from: data.id_type_money_from,
        p_id_type_money_to: data.id_type_money_to,
        p_value_exchange: data.value_exchange,
    })

    const [ready, setReady] = useState(false)
    const [rowsTypeMoney, setRowsTypeMoney] = useState([])

    const idTypeMoneyFrom = useMemo(() => {
        if (!row.p_id_type_money_from) {
            return
        }

        let finded = rowsTypeMoney.find(t => parseInt(row.p_id_type_money_from) === parseInt(t.id_type_money))

        if (!finded) {
            return
        }
        return {
            label: finded.name_money + ' - ' + finded.code_money,
            value: finded.id_type_money
        }
    }, [row.p_id_type_money_from, rowsTypeMoney])

    const idTypeMoneyTo = useMemo(() => {
        if (!row.p_id_type_money_to) {
            return
        }

        let finded = rowsTypeMoney.find(t => parseInt(row.p_id_type_money_to) === parseInt(t.id_type_money))

        if (!finded) {
            return
        }
        return {
            label: finded.name_money + ' - ' + finded.code_money,
            value: finded.id_type_money
        }
    }, [row.p_id_type_money_to, rowsTypeMoney])

    useEffect(() => {
        Controllers.type_money.get_type_money().then(res => {
            setRowsTypeMoney(res.data)
            setReady(true)
        })
    }, [])

    return <ReactPlaceholder ready={ready} showLoadingAnimation rows={10}>
        <FormCustom dataSubmit={row} onSubmit={({ data }) => {
            if (parseInt(row.p_id_type_money_from) === parseInt(row.p_id_type_money_to)) {
                Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar monedas diferentes', toast })
                return
            }

            onSubmit({ data })
        }} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
            <Input
                text="Fecha"
                type="date"
                placeholder="Fecha"
                classNameParent="col-12 mb-2"
                icon="far fa-calendar-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.date_exchange}
                onChange={e => setRow({ ...row, p_date_exchange: e.currentTarget.value })}
            />
            <Select
                icon="fa fa-dollar-sign"
                text="Origen"
                options={rowsTypeMoney.map(t => {
                    return {
                        label: t.name_money + ' - ' + t.code_money,
                        value: t.id_type_money
                    }
                })}
                classNameParent="col-12 mb-2"
                onChange={e => setRow({ ...row, p_id_type_money_from: e.value })}
                value={idTypeMoneyFrom}
            />
            <Select
                icon="fa fa-dollar-sign"
                text="Destino"
                options={rowsTypeMoney.map(t => {
                    return {
                        label: t.name_money + ' - ' + t.code_money,
                        value: t.id_type_money
                    }
                })}
                classNameParent="col-12 mb-2"
                onChange={e => setRow({ ...row, p_id_type_money_to: e.value })}
                value={idTypeMoneyTo}
            />
            <Input
                text="Valor"
                type="number"
                placeholder="Valor"
                classNameParent="col-12 mb-2"
                icon="fa fa-dollar-sign"
                required
                min="0"
                step="0.0001"
                invalid="El campo es obligatorio."
                defaultValue={data.value_exchange}
                onChange={e => setRow({ ...row, p_value_exchange: e.currentTarget.value })}
            />
        </FormCustom>
    </ReactPlaceholder>
}

ExchangeForm.defaultProps = {
    data: {
        id_exchange: '',
        date_exchange: '',
        id_type_money_from: '',
        id_type_money_to: '',
        value_exchange: '',
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default ExchangeForm;