import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Card from 'react-bootstrap/Card';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import Helpers from '../../Components/Helpers/Helpers';
import { toast } from 'react-toastify'

const Commissions = _ => {
    const [isProcessing, setIsProccesing] = useState(true)
    const [rows, setRows] = useState({
        customer: {},
        details: [],
        exchange: [],
        total_earnings: 0
    })

    useEffect(() => {
        setIsProccesing(true)
        Controllers.red.get_my_commissions().then(res => {
            setRows(res.data)
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setIsProccesing(false)
        })
    }, [])

    const dtRows = useMemo(() => <ReactDataTableBootstrap
        head={[
            [
                { name: 'id_customers', text: 'ID', align: 'center', sort: false, classBodyTr: r => {
                    return parseInt(r?.has_exchange) === 1 ? 'bg-info text-white' : ''
                } },
                { name: 'full_name', text: 'Nombres', sort: false },
                { name: 'point_business_customers', text: 'Valor Negocio', align: 'center', sort: false },
                { name: 'percentage', text: 'Porcentaje', align: 'center', sort: false, render: r => r.percentage + '%' },
                { name: 'subtotal_earnings', text: 'Ganancia', align: 'center', sort: false, render: r => '$ ' + r.subtotal_earnings },
                { name: 'nivel', text: 'Nivel', align: 'center', sort: false },
                { name: 'code_money', text: 'Moneda', align: 'center', sort: false },
            ],
        ]}
        rows={rows.details}
        pageLength={100}
        isProcessing={isProcessing}
        noSort
    />, [rows, isProcessing])

    return <>
        <Card>
            <Card.Header className="justify-content-between">
                <span>Comisiones</span>
            </Card.Header>
            <Card.Body>
                { dtRows }
            </Card.Body>
        </Card>
        {isProcessing ? '' : <>
            {rows.exchange.length > 0 ? <Card className="mt-4">
                <Card.Header>
                    <span>Detalle de Conversiones</span>
                </Card.Header>
                <Card.Body>
                    <div className="table-responsive">
                        <table className="table table-sm table-hover table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Cliente</th>
                                    <th>Moneda</th>
                                    <th>Ganancia</th>
                                    <th>Conversión</th>
                                    <th>Ganancia Conversión</th>
                                </tr>
                            </thead>
                            <tbody>
                                {React.Children.toArray(rows.exchange.map(e => {
                                    return <tr>
                                        <td>{e.full_name}</td>
                                        <td align="center">{e.code_money}</td>
                                        <td align="center">{e.subtotal_earnings}</td>
                                        <td align="center">{e.value_exchange}</td>
                                        <td align="center">{e.subtotal_exchange}</td>
                                    </tr>
                                }))}
                            </tbody>
                        </table>
                    </div>
                </Card.Body>
            </Card> : ''}
            <div className="d-flex justify-content-center mt-4">
                <div className="alert alert-success text-center">
                    <h5>Total de Comisiones: <strong>{rows.customer.code_money} {parseFloat(rows.total_earnings).toFixed(2)}</strong></h5>
                    <small>Total de comisiones antes de impuesto.</small>
                </div>
            </div>
        </>}
        
    </>
}

export default Commissions