import React, { useState, useMemo, useEffect, useCallback } from 'react'
import ReactPlaceholder from 'react-placeholder/lib'
import Controllers from '../../Api/Controllers'
import { Input, FormCustom, Select, SwitchToggle } from '../../Components/Form/Form'
import Helpers from '../../Components/Helpers/Helpers'
import ProductPhoto from '../ProductPhoto/ProductPhoto'
import { toast } from 'react-toastify';

const ProductForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_product: data.id_product,
        p_key_product: data.key_product,
        p_name_product: data.name_product,
        p_description_product: data.description_product,
        p_id_clasification: data.id_clasification,
        p_pack_product: data.pack_product,
        p_stock_min_product: data.stock_min_product,
        p_stock_max_product: data.stock_max_product,
        p_weight_product: data.weight_product,
        p_price_product: data.price_product,
        p_avaible_store_product: data.avaible_store_product,
        p_description_store_product: data.description_store_product,
        p_id_tax_1: data.id_tax_1,
        p_id_tax_2: data.id_tax_2,
        p_id_tax_3: data.id_tax_3,
        p_id_exempt_1: data.id_exempt_1,
        p_id_exempt_2: data.id_exempt_2,
        p_id_exempt_3: data.id_exempt_3,
        p_iva_product: data.iva_product,
        p_id_branch_office: data.id_branch_office
    })

    const [ready, setReady] = useState(false)
    const [rowsClasification, setRowsClasification] = useState([])
    const [rowsTax, setRowsTax] = useState([])
    const [rowsExempt, setRowsExempt] = useState([])
    const [rowsBranchOffice, setRowsBranchOffice] = useState([])

    const dataSubmit = useCallback(() => {
        return {
            ...row,
            p_pack_product: parseInt(row.p_pack_product) === 1 ? 1 : 0,
            p_avaible_store_product: parseInt(row.p_avaible_store_product) === 1 ? 1 : 0,
            p_tax: [
                { p_id_tax: row.p_id_tax_1, p_order_tax: 1 },
                { p_id_tax: row.p_id_tax_2, p_order_tax: 2 },
                { p_id_tax: row.p_id_tax_3, p_order_tax: 3 },
            ],
            p_exempt: [
                { p_id_exempt: row.p_id_exempt_1, p_order_exempt: 1 },
                { p_id_exempt: row.p_id_exempt_2, p_order_exempt: 2 },
                { p_id_exempt: row.p_id_exempt_3, p_order_exempt: 3 },
            ]
        }
    }, [row])

    useEffect(() => {
        let promises = [
            Controllers.clasification.get_clasification(),
            Controllers.tax.get_tax(),
            Controllers.exempt.get_exempt(),
            Controllers.branch_office.get_branch_office()
        ]

        Promise.all(promises).then(res => {
            setRowsClasification(res[0].data)
            setRowsTax(res[1].data)
            setRowsExempt(res[2].data)
            setRowsBranchOffice(res[3].data)
            setReady(true)
        })
    }, [])
    
    const clasification = useMemo(() => {
        if (!row.p_id_clasification) {
            return
        }

        let finded = rowsClasification.find(t => parseInt(row.p_id_clasification) === parseInt(t.id_clasification))

        if (!finded) {
            return
        }
        return {
            label: `${finded.code_clasification} - ${finded.name_clasification}`,
            value: finded.id_clasification
        }
    }, [row.p_id_clasification, rowsClasification])

    const branchOffice = useMemo(() => {
        if (!row.p_id_branch_office) {
            return
        }

        let finded = rowsBranchOffice.find(t => parseInt(row.p_id_branch_office) === parseInt(t.id_branch_office))

        if (!finded) {
            return
        }
        return {
            label: finded.key_branch_office + ' - ' +  finded.name_branch_office,
            value: finded.id_branch_office
        }
    }, [row.p_id_branch_office, rowsBranchOffice])
    
    const tax_1 = useMemo(() => {
        if (!row.p_id_tax_1) {
            return
        }

        let finded = rowsTax.find(t => parseInt(t.id_tax) === parseInt(row.p_id_tax_1))

        if (!finded) {
            return
        }

        return {
            label: `${finded.name_tax} - ${finded.value_tax}`,
            value: finded.id_tax
        }
    }, [row.p_id_tax_1, rowsTax])

    const tax_2 = useMemo(() => {
        if (!row.p_id_tax_2) {
            return
        }

        let finded = rowsTax.find(t => parseInt(t.id_tax) === parseInt(row.p_id_tax_2))

        if (!finded) {
            return
        }

        return {
            label: `${finded.name_tax} - ${finded.value_tax}`,
            value: finded.id_tax
        }
    }, [row.p_id_tax_2, rowsTax])

    const tax_3 = useMemo(() => {
        if (!row.p_id_tax_3) {
            return
        }

        let finded = rowsTax.find(t => parseInt(t.id_tax) === parseInt(row.p_id_tax_3))

        if (!finded) {
            return
        }

        return {
            label: `${finded.name_tax} - ${finded.value_tax}`,
            value: finded.id_tax
        }
    }, [row.p_id_tax_3, rowsTax])
    
    const exempt_1 = useMemo(() => {
        if (!row.p_id_exempt_1) {
            return
        }

        let finded = rowsExempt.find(t => parseInt(t.id_exempt) === parseInt(row.p_id_exempt_1))

        if (!finded) {
            return
        }

        return {
            label: `${finded.name_exempt} - ${finded.value_exempt}`,
            value: finded.id_exempt
        }
    }, [row.p_id_exempt_1, rowsExempt])

    const exempt_2 = useMemo(() => {
        if (!row.p_id_exempt_2) {
            return
        }

        let finded = rowsExempt.find(t => parseInt(t.id_exempt) === parseInt(row.p_id_exempt_2))

        if (!finded) {
            return
        }

        return {
            label: `${finded.name_exempt} - ${finded.value_exempt}`,
            value: finded.id_exempt
        }
    }, [row.p_id_exempt_2, rowsExempt])

    const exempt_3 = useMemo(() => {
        if (!row.p_id_exempt_3) {
            return
        }

        let finded = rowsExempt.find(t => parseInt(t.id_exempt) === parseInt(row.p_id_exempt_3))

        if (!finded) {
            return
        }

        return {
            label: `${finded.name_exempt} - ${finded.value_exempt}`,
            value: finded.id_exempt
        }
    }, [row.p_id_exempt_3, rowsExempt])


    return <ReactPlaceholder ready={ready} rows={7}>
            <FormCustom dataSubmit={dataSubmit()} onSubmit={({ data }) => {
                if (!row.p_id_clasification) {
                    Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar la clasificación', toast })
                    return 
                }
    
                /* if (!row.p_id_tax_1 || !row.p_id_tax_2 || !row.p_id_tax_3) {
                    Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar los impuestos', toast })
                    return 
                }
    
                if ((parseInt(row.p_id_tax_1) === parseInt(row.p_id_tax_2)) || (parseInt(row.p_id_tax_1) === parseInt(row.p_id_tax_3)) || (parseInt(row.p_id_tax_2) === parseInt(row.p_id_tax_3))) {
                    Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar diferentes impuestos', toast })
                    return 
                }

                if (!row.p_id_exempt_1 || !row.p_id_exempt_2 || !row.p_id_exempt_3) {
                    Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar los excentos', toast })
                    return 
                }
    
                if ((parseInt(row.p_id_exempt_1) === parseInt(row.p_id_exempt_2)) || (parseInt(row.p_id_exempt_1) === parseInt(row.p_id_exempt_3)) || (parseInt(row.p_id_exempt_2) === parseInt(row.p_id_exempt_3))) {
                    Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar diferentes excentos', toast })
                    return 
                } */


                onSubmit({ data })
            }} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
            <Input
                text="Clave"
                placeholder="Clave"
                classNameParent="col-6 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                disabled={parseInt(data.id_product) > 0}
                defaultValue={data.key_product}
                onChange={e => setRow({ ...row, p_key_product: e.currentTarget.value })}
                maxLength={100}
            />
            <Input
                text="Nombre"
                placeholder="Nombre"
                classNameParent="col-6 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.name_product}
                onChange={e => setRow({ ...row, p_name_product: e.currentTarget.value })}
                maxLength={255}
            />
            <Input
                text="Descripción"
                placeholder="Descripción"
                classNameParent="col-6 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.description_product}
                onChange={e => setRow({ ...row, p_description_product: e.currentTarget.value })}
            />
            <Select
                classNameParent="col-6 mb-2"
                icon="fas fa-dollar-sign"
                text="Clasificación"
                options={rowsClasification.map(t => {
                    return {
                        label: `${t.code_clasification} - ${t.name_clasification}`,
                        value: t.id_clasification
                    }
                })}
                value={clasification}
                onChange={e => setRow({ ...row, p_id_clasification: e.value })}
            />
            <Select
                classNameParent="col-6 mb-2"
                icon="far fa-building"
                text="Sucursal"
                options={rowsBranchOffice.map(t => {
                    return {
                        label: t.key_branch_office + ' - ' +  t.name_branch_office,
                        value: t.id_branch_office
                    }
                })}
                value={branchOffice}
                onChange={e => setRow({ ...row, p_id_branch_office: e.value })}
            />
            <SwitchToggle
                id="product-is-pack"
                defaultChecked={parseInt(data.pack_product) === 1}
                onChange={e => setRow({ ...row, p_pack_product: e.currentTarget.checked ? 1 : 0 })}
                classNameParent="col-12 mb-2"
                textCheck="Es Paquete"
                textUnChecked="No Es Paquete"
            />
            <Input
                text="Stock Mínimo"
                placeholder="Stock Mínimo"
                type="number"
                classNameParent="col-6 mb-2"
                icon="fas fa-dollar-sign"
                required
                invalid="El campo es obligatorio."
                min="0.10"
                step="0.10"
                defaultValue={data.stock_min_product}
                onChange={e => setRow({ ...row, p_stock_min_product: e.currentTarget.value })}
            />
            <Input
                text="Stock Maximo"
                placeholder="Stock Maximo"
                type="number"
                classNameParent="col-6 mb-2"
                icon="fas fa-dollar-sign"
                required
                invalid="El campo es obligatorio."
                min="0.10"
                step="0.10"
                defaultValue={data.stock_max_product}
                onChange={e => setRow({ ...row, p_stock_max_product: e.currentTarget.value })}
            />
            <Input
                text="Peso"
                placeholder="Peso"
                type="number"
                classNameParent="col-6 mb-2"
                icon="fas fa-dollar-sign"
                required
                invalid="El campo es obligatorio."
                min="0.10"
                step="0.10"
                defaultValue={data.weight_product}
                onChange={e => setRow({ ...row, p_weight_product: e.currentTarget.value })}
            />
            <Input
                text="Costo Producción"
                placeholder="Costo Producción"
                type="number"
                classNameParent="col-6 mb-2"
                icon="fas fa-dollar-sign"
                required
                invalid="El campo es obligatorio."
                min="0.10"
                step="0.10"
                defaultValue={data.price_product}
                onChange={e => setRow({ ...row, p_price_product: e.currentTarget.value })}
            />
            <Select
                classNameParent="col-6 mb-2"
                icon="fas fa-percentage"
                text="Impuesto 1"
                options={rowsTax.map(t => {
                    return {
                        label: `${t.name_tax} - ${t.value_tax}`,
                        value: t.id_tax
                    }
                })}
                value={tax_1}
                onChange={e => setRow({ ...row, p_id_tax_1: e.value })}
            />
            <Select
                classNameParent="col-6 mb-2"
                icon="fas fa-percentage"
                text="Impuesto 2"
                options={rowsTax.map(t => {
                    return {
                        label: `${t.name_tax} - ${t.value_tax}`,
                        value: t.id_tax
                    }
                })}
                value={tax_2}
                onChange={e => setRow({ ...row, p_id_tax_2: e.value })}
            />
            <Select
                classNameParent="col-6 mb-2"
                icon="fas fa-percentage"
                text="Impuesto 3"
                options={rowsTax.map(t => {
                    return {
                        label: `${t.name_tax} - ${t.value_tax}`,
                        value: t.id_tax
                    }
                })}
                value={tax_3}
                onChange={e => setRow({ ...row, p_id_tax_3: e.value })}
            />
            {/* <Input
                text="IVA"
                placeholder="IVA"
                classNameParent="col-6 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.iva_product}
                onChange={e => setRow({ ...row, p_iva_product: e.currentTarget.value })}
            /> */}

            <p className="col-12 mb-1 mt-2 font-weight-bold">
                Información de Tienda en Línea
            </p>
            <SwitchToggle
                id="product-avaible-store"
                defaultChecked={parseInt(data.avaible_store_product) === 1}
                onChange={e => setRow({ ...row, p_avaible_store_product: e.currentTarget.checked ? 1 : 0 })}
                classNameParent="col-12 mb-2"
                textCheck="Disponible en Tienda Online"
                textUnChecked="No Disponible en Tienda Online"
            />
            <Select
                classNameParent="col-6 mb-2"
                icon="fas fa-percentage"
                text="Excento 1"
                options={rowsExempt.map(t => {
                    return {
                        label: `${t.name_exempt} - ${t.value_exempt}`,
                        value: t.id_exempt
                    }
                })}
                value={exempt_1}
                onChange={e => setRow({ ...row, p_id_exempt_1: e.value })}
            />
            <Select
                classNameParent="col-6 mb-2"
                icon="fas fa-percentage"
                text="Excento 2"
                options={rowsExempt.map(t => {
                    return {
                        label: `${t.name_exempt} - ${t.value_exempt}`,
                        value: t.id_exempt
                    }
                })}
                value={exempt_2}
                onChange={e => setRow({ ...row, p_id_exempt_2: e.value })}
            />
            <Select
                classNameParent="col-6 mb-2"
                icon="fas fa-percentage"
                text="Excento 3"
                options={rowsExempt.map(t => {
                    return {
                        label: `${t.name_exempt} - ${t.value_exempt}`,
                        value: t.id_exempt
                    }
                })}
                value={exempt_3}
                onChange={e => setRow({ ...row, p_id_exempt_3: e.value })}
            />
            <Input
                text="Descripción Tienda en Línea"
                placeholder="Descripción Tienda en Línea"
                classNameParent="col-6 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.description_store_product}
                onChange={e => setRow({ ...row, p_description_store_product: e.currentTarget.value })}
            />
        </FormCustom>
        {data.id_product ? <div className="mt-3">
            <ProductPhoto nameSingle={Helpers.string.toCamelCase('Foto')} namePlural={Helpers.string.toCamelCase('Fotos')} separator={'el'.toLowerCase()} idProduct={data.id_product} />
        </div> : ''}
    </ReactPlaceholder>
}

ProductForm.defaultProps = {
    data: {
        id_product: '',
        name_product: '',
        key_product: '',
        description_product: '',
        id_clasification: '',
        pack_product: 0,
        stock_min_product: '',
        stock_max_product: '',
        weight_product: '',
        price_product: '',
        avaible_store_product: 0,
        description_store_product: '',
        id_tax_1: '',
        id_tax_2: '',
        id_tax_3: '',
        id_exempt_1: '',
        id_exempt_2: '',
        id_exempt_3: '',
        iva_product: '',
        id_branch_office: ''
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default ProductForm;