import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import Helpers from '../../Components/Helpers/Helpers';
import { toast } from 'react-toastify'
import { FormCustom, Input } from '../../Components/Form/Form';
import Button from 'react-bootstrap/Button'
import Loading from '../../Components/Layout/Styled/Loading'
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/esm/Modal';

const ReporteSaleCustomers = _ => {
    const [isProcessing, setIsProccesing] = useState(false)
    const [rows, setRows] = useState({})
    const [idDocumentPdf, setIdDocumentPdf] = useState('')
    const [modalShowPdf, setModalShowPdf] = useState(false);
    const jwt = useSelector(store => store.session.jwt)

    const dtRows = useMemo(() => <ReactDataTableBootstrap
        head={[
            [
                { name: 'id_document', text: 'ID', align: 'center' },
                { name: 'date_doc', text: 'Fecha', align: 'center' },
                { name: 'qty_total', text: 'Cantidad', align: 'center' },
                { name: 'total_doc', text: 'Total', align: 'center' },
                { name: 'point_total', text: 'Puntos', align: 'center' },
                { name: 'value_business_total', text: 'Valor Negocio', align: 'center' },
                { name: 'key_branch_office', text: 'Sucursal', align: 'center' },
                { name: 'actions', text: <i className="fa fa-cog"></i>, align: 'center', render: u => <Button variant="danger" className="ml-1" size="xs" onClick={_ => {
                    setIdDocumentPdf(u.id_document)
                    setModalShowPdf(true)
                }}>
                    <i className="fa fa-file-pdf"></i>
                </Button> }
            ],
        ]}
        rows={rows?.data ? rows.data : []}
        pageLength={100}
        isProcessing={isProcessing}
    />, [rows, isProcessing])

    const [data, setData] = useState({
        p_id_customers: '',
        p_fulle_name_customers: ''
    })
    const [showLoading, setShowLoading] = useState(false)

    return <>
        <Card>
            <Card.Header className="justify-content-between">
                <span>Ventas por Cliente</span>
            </Card.Header>
            <Card.Body>
                <FormCustom viewSubmit={false} onSubmit={_ => {
                    setRows({})
                    setIsProccesing(true)
                    setShowLoading(true)
                    Controllers.document.get_customers_find_sale({
                        p_id_customers: data.p_id_customers
                    }).then(res => {
                        if (res.response !== 'success') {
                            Helpers.toast.construct({ ...res, toast })
                            return
                        }
                        setData({ p_id_customers: res.data.id_customers, p_fulle_name_customers: res.data.name_customers })
                        setRows(res.data)
                    }).catch(req => Helpers.promise.catch({ req, toast }))
                    .finally(() => {
                        setIsProccesing(false)
                        setShowLoading(false)
                    })
                }}>
                    <Input
                        classNameParent="col-12 mb-2"
                        type="number"
                        pattern="[0-9]{1,9}"
                        icon="fa fa-user"
                        placeholder="Id Cliente"
                        text="Id Cliente"
                        required
                        onChange={e => {
                            setData({ ...data, p_id_customers: e.currentTarget.value, p_fulle_name_customers: '' })
                            setRows([])
                        }}
                        append={[
                            <Button type="submit">
                                <i className="fa fa-search"></i>
                            </Button>
                        ]}
                        value={data.p_id_customers}
                    />
                    <Input
                        classNameParent="col-12 mb-4"
                        icon="fa fa-user"
                        placeholder="Nombre Cliente"
                        text="Nombre Cliente"
                        required
                        disabled
                        value={data.p_fulle_name_customers}
                    />
                </FormCustom>
                { dtRows }
            </Card.Body>
        </Card>
        <Loading show={showLoading}>
            <Loading.Img src={Helpers.config.resourcesUrl({ url: '/images/dashboard/preloading.svg' })} alt="Preloading" />
        </Loading>
        <Modal show={modalShowPdf} onHide={_ => setModalShowPdf(false)} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title as="div">Documento de la Venta</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <iframe className="w-100" style={{ 
                    minHeight: 500
                 }} src={Helpers.config.apiUrl({ url: '/sale/generate/invoice/' + idDocumentPdf + '?jwt=' + jwt })}></iframe>
            </Modal.Body>
        </Modal>
    </>
}

export default ReporteSaleCustomers