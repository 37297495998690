import React, { useState } from 'react'
import Card from 'react-bootstrap/Card';
import Controllers from '../../Api/Controllers';
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
import CustomersForm from './CustomersForm';
import { useHistory } from 'react-router';

const SaveMyCustomers = () => {
    const [loadSubmit, setLoadSubmit] = useState(false)
    const history = useHistory()

    const handleInsertUpdate = ({ data }) => {
        setLoadSubmit(true)
        Controllers.customers.customers_insert_update(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                history.push(Helpers.config.convertUrl({ url: '/descendia-de-red' }))
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setLoadSubmit(false)
        })
    }

    return <>
        <Card>
            <Card.Header className="justify-content-between">
                <span>Registrar Cliente</span>
            </Card.Header>
            <Card.Body>
                <CustomersForm onSubmit={handleInsertUpdate} saveMyCustomers loadSubmit={loadSubmit} />
            </Card.Body>
        </Card>
    </>
}

export default SaveMyCustomers