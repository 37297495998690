import React, { useState } from 'react'
import { Input, FormCustom } from '../../Components/Form/Form'

const BankForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_language: data.id_language,
        p_name_language: data.name_language,
        p_code_language: data.code_language
    })

    return <FormCustom dataSubmit={row} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
        <Input
            text="Descripción"
            placeholder="Descripción"
            classNameParent="col-12 mb-2"
            icon="far fa-comment-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.name_language}
            onChange={e => setRow({ ...row, p_name_language: e.currentTarget.value })}
            maxLength={150}
        />
        <Input
            text="Código"
            placeholder="Código"
            classNameParent="col-12 mb-2"
            icon="fas fa-code"
            required
            invalid="Formato incorrecto. Ej: ESP, ENG"
            defaultValue={data.code_language}
            onChange={e => setRow({ ...row, p_code_language: e.currentTarget.value })}
            maxLength={5}
        />
    </FormCustom>
}

BankForm.defaultProps = {
    data: {
        id_language: '',
        name_language: '',
        code_language: ''
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default BankForm;