import React, { useState } from 'react'
import { Input, FormCustom, SwitchToggle } from '../../Components/Form/Form'

const DispatchForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_dispatch: data.id_dispatch,
        p_name_dispatch: data.name_dispatch,
        p_color_dispatch: data.color_dispatch,
        p_enabled_dispatch: data.enabled_dispatch
    })

    return <>
        <FormCustom dataSubmit={row} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
            <Input
                text="Descripción"
                placeholder="Descripción"
                classNameParent="col-12 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.name_dispatch}
                onChange={e => setRow({ ...row, p_name_dispatch: e.currentTarget.value })}
                maxLength={150}
            />
            <Input
                text="Color"
                placeholder="Color"
                classNameParent="col-12 mb-2"
                icon="fas fa-code"
                invalid="Error en formato. Ej: MX, USA"
                defaultValue={data.color_dispatch}
                onChange={e => setRow({ ...row, p_color_dispatch: e.currentTarget.value })}
                maxLength={255}
            />
            <div className="col-12">
                <hr />
                <p>Vista Previa: <span className={'badge badge-' + row.p_color_dispatch}>{row.p_name_dispatch}</span></p>
            </div>
            <SwitchToggle
                classNameParent="col-12 mb-2"
                id="dispatch_state"
                defaultChecked={parseInt(row.p_enabled_dispatch) === 1}
                onChange={e => setRow({ ...row, p_enabled_dispatch: e.currentTarget.checked ? 1 : 0 })}
            />
        </FormCustom>
        <div className="w-100">
            <hr />
            <small>Colores:</small>
            {React.Children.toArray([ 'danger', 'success', 'dark', 'light', 'warning', 'secondary', 'info' ].map(c => <div className="d-flex align-items-center flex-row flex-nowrap">
                <div className={'bg-' + c} style={{ width: 10, height: 10 }}></div>
                <small className="ml-2">{c}</small>
            </div>))}
        </div>
    </>
}

DispatchForm.defaultProps = {
    data: {
        id_dispatch: '',
        name_dispatch: '',
        color_dispatch: '',
        enabled_dispatch: 0
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default DispatchForm;