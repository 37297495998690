import React, { useState, useCallback } from 'react'
import { Input, FormCustom } from '../../Components/Form/Form'
import Helpers from '../../Components/Helpers/Helpers'

const ProductPhotoForm = ({ data, onSubmit, disablebSubmit, loadSubmit, idProduct }) => {
    const [row, setRow] = useState({
        p_id_photo: data.id_photo,
        p_id_product: idProduct,
        p_name_photo: data.name_photo,
        p_alt_photo: data.alt_photo,
        p_file_photo: data.file_photo
    })

    const dataSubmit = useCallback(() => {
        let formData = new FormData()

        for (const key in row) {
            if (key === 'p_file_photo') {
                if (row.p_file_photo?.files) {
                    if (row.p_file_photo.files.length > 0) {
                        console.log(row.p_file_photo.files[0])
                        formData.append('p_file_photo', row.p_file_photo.files[0])
                        continue
                    }
                }
                formData.append('p_file_photo', '')
                continue
            }
            formData.append(key, row[key])
        }

        return formData
    }, [row])

    return <FormCustom dataSubmit={dataSubmit()} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
        <Input
            text="Nombre"
            placeholder="Nombre"
            classNameParent="col-6 mb-2"
            icon="far fa-comment-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.name_photo}
            onChange={e => setRow({ ...row, p_name_photo: e.currentTarget.value })}
            maxLength={100}
        />
        <Input
            text="Alt SEO"
            placeholder="Alt SEO"
            classNameParent="col-6 mb-2"
            icon="far fa-comment-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.alt_photo}
            onChange={e => setRow({ ...row, p_alt_photo: e.currentTarget.value })}
            maxLength={100}
        />
        <Input
            icon="fa fa-image"
            type="file"
            text="Imagen"
            required
            classNameParent="col-12 mb-2"
            accept="image/png, image/jpe"
            required={data.file_photo === ''}
            onChange={e => setRow({ ...row, p_file_photo: e.currentTarget })}
        />
        {data.file_photo ? <div className="col-12">
            <a href={Helpers.config.resourcesUrl({ url: data.file_photo })} target="_blank">
                <img src={Helpers.config.resourcesUrl({ url: data.file_photo })} width="300" alt={data.alt_photo} className="img-thumbnail" />
            </a>
        </div> : ''}
    </FormCustom>
}

ProductPhotoForm.defaultProps = {
    data: {
        id_photo: '',
        name_photo: '',
        alt_photo: '',
        file_photo: '',
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default ProductPhotoForm;