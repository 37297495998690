import React, { useState } from 'react'
import { Input, FormCustom } from '../../Components/Form/Form'

const PeriodForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_period: data.id_period,
        p_name_period: data.name_period,
        p_code_period: data.code_period,
        p_date_start_period: data.date_start_period,
        p_date_end_period: data.date_end_period,
    })

    return <FormCustom dataSubmit={row} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
        <Input
            text="Descripción"
            placeholder="Descripción"
            classNameParent="col-12 mb-2"
            icon="far fa-comment-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.name_period}
            onChange={e => setRow({ ...row, p_name_period: e.currentTarget.value })}
            maxLength={150}
        />
        <Input
            text="Código"
            placeholder="Código"
            classNameParent="col-12 mb-2"
            icon="far fa-comment-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.code_period}
            onChange={e => setRow({ ...row, p_code_period: e.currentTarget.value })}
            maxLength={30}
        />
        <Input
            text="Fecha Inicio"
            placeholder="Fecha Inicio"
            classNameParent="col-12 mb-2"
            icon="far fa-calendar-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.date_start_period}
            onChange={e => setRow({ ...row, p_date_start_period: e.currentTarget.value })}
            type="date"
        />
        <Input
            text="Fecha Inicio"
            placeholder="Fecha Inicio"
            classNameParent="col-12 mb-2"
            icon="far fa-calendar-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.date_end_period}
            onChange={e => setRow({ ...row, p_date_end_period: e.currentTarget.value })}
            type="date"
        />
    </FormCustom>
}

PeriodForm.defaultProps = {
    data: {
        id_period: '',
        name_period: '',
        code_period: '',
        date_start_period: '',
        date_end_period: ''
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default PeriodForm;