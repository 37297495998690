import React, { useState } from 'react'
import { Input, FormCustom, Select, SwitchToggle } from '../../Components/Form/Form'

const FileForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_file: data.id_file,
        p_name_file: data.name_file,
        p_type_file: data.type_file,
        p_path_file: data.path_file,
        p_enabled_file: data.enabled_file
    })

    const typeFile = [
        'DRIVE',
        'EXCEL',
        'IMAGEN',
        'PDF',
        'WORD',
        'YOUTUBE',
        'OTROS'
    ]

    return <FormCustom dataSubmit={row} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
        <Input
            text="Descripción"
            placeholder="Descripción"
            classNameParent="col-12 mb-2"
            icon="far fa-comment-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.name_file}
            onChange={e => setRow({ ...row, p_name_file: e.currentTarget.value })}
            maxLength={150}
        />
        <Select
            classNameParent="col-12 mb-2"
            icon="fa fa-file"
            text="Tipo"
            options={typeFile.map(t => {
                return {
                    label: t,
                    value: t
                }
            })}
            value={row.p_type_file ? { 
                label: row.p_type_file,
                value: row.p_type_file
            } : ''}
            onChange={e => setRow({ ...row, p_type_file: e.value })}
        />
        <Input
            text="URL"
            placeholder="URL"
            classNameParent="col-12 mb-2"
            icon="far fa-comment-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.path_file}
            onChange={e => setRow({ ...row, p_path_file: e.currentTarget.value })}
            maxLength={150}
        />
        <SwitchToggle
            id="enabled-file"
            defaultChecked={parseInt(data.enabled_file) === 1}
            onChange={e => setRow({ ...row, p_enabled_file: e.currentTarget.checked ? 1 : 0 })}
            classNameParent="col-12 mb-2"
            textCheck="Activo"
            textUnChecked="inactivo"
        />
    </FormCustom>
}

FileForm.defaultProps = {
    data: {
        id_file: '',
        name_file: '',
        type_file: '',
        path_file: '',
        enabled_file: 0
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default FileForm;