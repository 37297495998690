import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Card from 'react-bootstrap/Card';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import Helpers from '../../Components/Helpers/Helpers';
import { toast } from 'react-toastify'

const HistoryCommisions = _ => {
    const [ready, setReady] = useState(false)
    const [isProcessing, setIsProccesing] = useState(false)
    const [rows, setRows] = useState({
        total: 0,
        details: [],
        code_money: ''
    })

    useEffect(() => {
        setIsProccesing(true)
        Controllers.report.get_report_commissions_period_by_customers().then(res => {
            setRows(res.data)
        }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setIsProccesing(false)
            })
    }, [])

    const dtRows = useMemo(() => <ReactDataTableBootstrap
        head={[
            [
                { name: 'name_period', text: 'Periódo', sort: false, align: 'center' },
                { name: 'name_plan', text: 'Rango', sort: false, align: 'center' },
                { name: 'subtotal_earnings', text: 'Ganancia', align: 'center', sort: false, render: r => '$ ' + r.subtotal_earnings },
                { name: 'isr', text: 'ISR', align: 'center', sort: false, render: r => '$ ' + r.isr },
                { name: 'total', text: 'Total', align: 'center', sort: false, render: r => '$ ' + r.total },
                { name: 'code_money', text: 'Moneda', align: 'center', sort: false },
            ],
        ]}
        rows={rows.details}
        pageLength={100}
        isProcessing={isProcessing}
        noSort
    />, [rows, isProcessing])

    return <>
        <Card>
            <Card.Header className="justify-content-between">
                <span>Historial de Comisiones</span>
            </Card.Header>
            <Card.Body>
                {dtRows}
            </Card.Body>
        </Card>
        {isProcessing ? '' : <div className="d-flex justify-content-center mt-4">
            <div className="alert alert-success text-center">
                <h5>Total de Comisiones: <strong>{rows.code_money} {rows.total}</strong></h5>
            </div>
        </div>}
    </>
}

export default HistoryCommisions