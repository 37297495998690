import React, { useState, useMemo, useEffect, useCallback } from 'react'
import Button from 'react-bootstrap/esm/Button'
import ReactPlaceholder from 'react-placeholder/lib'
import Controllers from '../../Api/Controllers'
import { Input, FormCustom, TextArea, Select, SwitchToggle } from '../../Components/Form/Form'
import Helpers from '../../Components/Helpers/Helpers'
import ProductList from '../Product/ProductList'
import { toast } from 'react-toastify';
import CustomersList from '../Customers/CustomersList'
import Loading from '../../Components/Layout/Styled/Loading'

const SaleForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_document: data.id_document,
        p_number_invoice_doc: data.number_invoice_doc,
        p_id_customers: data.id_customers,
        p_id_type_price: data.id_type_price,
        p_full_name_customers: data.full_name_customers,
        p_name_branch_office: data.name_branch_office,
        p_email_customers: data.email_customers,
        p_point_current_customers: data.point_current_customers,
        p_has_invoice_doc: data.has_invoice_doc,
        p_concept_cfdi_doc: data.concept_cfdi_doc,
        p_id_branch_office: data.id_branch_office,
        p_id_period: data.id_period,
        p_code_period: data.code_period,
        p_details: data.details,
        p_id_type_format_pay: data.id_type_format_pay,
        p_id_dispatch: data.id_dispatch
    })

    const [ivaTax, setIvaTax] = useState(parseFloat(data.value_tax))

    const notExistsStock = {
        exists: false,
        details: {
            message: '',
            products: []
        }
    }

    const [showLoading, setShowLoading] = useState(false)

    const [ready, setReady] = useState(false)
    const [modalProducts, setModalProducts] = useState(false)
    const [showModalCustomers, setShowModalCustomers] = useState(false)
    const [existsStock, setExistsStock] = useState({
        ...notExistsStock,
        exists: !isNaN(parseInt(data.id_document))
    })

    const [listTypeFormatPay, setListTypeFormatPay] = useState([])
    const [listBranchOffice, setListBranchOffice] = useState([])
    const [listStatusDispatch, setListStatusDispatch] = useState([])

    const typeFormayPay = useMemo(() => {
        if (!row.p_id_type_format_pay) {
            return
        }

        let finded = listTypeFormatPay.find(t => parseInt(row.p_id_type_format_pay) === parseInt(t.id_type_format_pay))

        if (!finded) {
            return
        }
        return {
            label: finded.name_format_pay,
            value: finded.id_type_format_pay
        }
    }, [row.p_id_type_format_pay, listTypeFormatPay])

    const statusDispatch = useMemo(() => {
        if (!row.p_id_dispatch) {
            return
        }

        let finded = listStatusDispatch.find(t => parseInt(row.p_id_dispatch) === parseInt(t.id_dispatch))

        if (!finded) {
            return
        }
        return {
            label: finded.name_dispatch,
            value: finded.id_dispatch
        }
    }, [row.p_id_dispatch, listStatusDispatch])

    const branchOffice = useMemo(() => {
        if (!row.p_id_branch_office) {
            return ''
        }

        let finded = listBranchOffice.find(t => parseInt(row.p_id_branch_office) === parseInt(t.id_branch_office))

        if (!finded) {
            return ''
        }
        return {
            label: finded.name_branch_office,
            value: finded.id_branch_office
        }
    }, [row.p_id_branch_office, listBranchOffice])

    const handleValidateStock = () => {
        if (row.p_details.length === 0) {
            Helpers.toast.construct({ response: 'warning', message: 'Por favor, agregar productos', toast })
            return
        }

        if (row.p_details.some(d => isNaN(parseInt(d.qty)))) {
            Helpers.toast.construct({ response: 'warning', message: 'Por favor, agregar cantidades', toast })
            return
        }

        if (!existsStock.exists) {
            setShowLoading(true)
            Controllers.product.get_product_validate_stock({
                p_details: row.p_details.map(r => {
                    return {
                        p_id_product: r.id_product,
                        p_key_product: r.key_product,
                        p_name_product: r.name_product,
                        p_price: r.price,
                        p_qty: r.qty,
                        p_point: r.point,
                        p_value_business: r.value_business,
                        p_pack_product: r.pack_product
                    }
                })
            }).then(res => {
                setExistsStock({
                    exists: res.response === 'success',
                    details: {
                        message: res.message,
                        products: res.data
                    }
                })
            }).catch(_ => {
                setExistsStock({
                    exists: false,
                    details: {
                        message: 'No se ha podido verificar stock.',
                        products: []
                    }
                })
            }).finally(_ => setShowLoading(false))
        }
    }

    const handleShowModalProducts = () => {
        if (!row.p_id_customers) {
            Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar Cliente', toast })
            return
        }

        setModalProducts(true)
    }
    const handleHideModalProducts = () => setModalProducts(false)

    useEffect(() => {
        Promise.all([
            Controllers.period.get_period_current(),
            Controllers.type_format_pay.get_type_format_pay(),
            Controllers.branch_office.get_branch_office(),
            Controllers.dispatch.get_dispatch()
        ]).then(res => {
            if (res[0].data?.id_period && data.id_document === '') {
                setRow({ ...row, p_id_period: res[0].data?.id_period, p_code_period: res[0].data?.code_period })
            }
            setListTypeFormatPay(res[1].data)
            setListBranchOffice(res[2].data)
            setListStatusDispatch(res[3].data)
            setReady(true)
        })
    }, [])

    const getSubtotal = useCallback(() => {
        let total = 0
        row.p_details.forEach(r => {
            let subt = parseFloat(r.qty) * parseFloat(r.price)
            if (!isNaN(parseFloat(r.qty) * parseFloat(r.price))) {
                total += subt
            }
        })
        let subtotal = parseFloat(total / (1 + ivaTax)).toFixed(2)

        return {
            total: total.toFixed(2),
            subtotal,
            iva: parseFloat(total - parseFloat(total / (1 + ivaTax))).toFixed(2)
        }
    }, [row.p_details, ivaTax])

    const dataSubmit = useCallback(() => {
        return {
            ...row,
            p_details: row.p_details.map(r => {
                return {
                    p_id_product: r.id_product,
                    p_key_product: r.key_product,
                    p_name_product: r.name_product,
                    p_price: r.price,
                    p_qty: r.qty,
                    p_point: r.point,
                    p_value_business: r.value_business,
                    p_pack_product: r.pack_product
                }
            }),
            p_total_doc: getSubtotal().total,
            p_subtotal_doc: getSubtotal().subtotal,
            p_iva_doc: getSubtotal().iva,
            p_id_type_format_pay: row.p_id_type_format_pay
        }
    }, [row, getSubtotal])

    const pointRest = useCallback(() => {
        if (row.p_id_customers) {
            let point = 0
            row.p_details.forEach(d => {
                point += isNaN(parseInt(d.qty)) ? 0 : parseInt(d.point) * parseInt(d.qty)
            })

            let rest = (Helpers.config.pointMinRange + parseInt(data.point_sale)) - (point + parseInt(row.p_point_current_customers))
            return rest > 0 ? rest : 0
        }

        return ''
    }, [row, data.point_sale])

    const pointCurrent = useCallback(() => {
        if (row.p_id_customers) {
            let point = 0
            row.p_details.forEach(d => {
                point += isNaN(parseInt(d.qty)) ? 0 : parseFloat(d.point) * parseInt(d.qty)
            })

            return (row.p_point_current_customers - data.point_sale) + point
        }

        return ''
    }, [row, data.point_sale])

    return <>
        <ReactPlaceholder rows={7} ready={ready}>
            <FormCustom dataSubmit={dataSubmit()} onSubmit={({ data }) => {
                if (!existsStock.exists) {
                    Helpers.toast.construct({ response: 'warning', message: 'Por favor, Verifique Stock', toast })
                    return
                }

                if (!row.p_id_customers) {
                    Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccione el Cliente.', toast })
                    return
                }

                if (row.p_details.length === 0) {
                    Helpers.toast.construct({ response: 'warning', message: 'Por favor, agregar productos.', toast })
                    return
                }

                if (!row.p_id_period) {
                    Helpers.toast.construct({ response: 'warning', message: 'No hay periodo activo. Comunicarse con el Área responsable.', toast })
                    return
                }

                if (!row.p_id_type_format_pay) {
                    Helpers.toast.construct({ response: 'warning', message: 'Por favor, seleccionar Forma de Pago.', toast })
                    return
                }

                return onSubmit({ data })
            }} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
                <div className="col-12">
                    <small className="text-muted">Información del Cliente</small>
                </div>
                <Input
                    text="Nombre Cliente"
                    placeholder="Nombre Cliente"
                    classNameParent="col-6 mb-2"
                    icon="far fa-user"
                    value={row.p_full_name_customers}
                    disabled
                    append={[
                        <Button onClick={_ => setShowModalCustomers(true)}>
                            <i className="fa fa-search"></i>
                        </Button>
                    ]}
                />
                <Input
                    text="Email"
                    placeholder="Email"
                    classNameParent="col-6 mb-2"
                    icon="far fa-envelope"
                    value={row.p_email_customers}
                    disabled
                />
                <Input
                    text="Puntos Actuales"
                    placeholder="Puntos Actuales"
                    classNameParent="col-6 mb-2"
                    icon="fas fa-sort-numeric-up-alt"
                    value={pointCurrent()}
                    className="text-center"
                    disabled
                />
                <Input
                    text="Puntos Faltantes"
                    placeholder="Puntos Faltantes"
                    classNameParent="col-6 mb-2"
                    icon="fas fa-sort-numeric-up-alt"
                    className="text-center"
                    value={pointRest()}
                    disabled
                />
                {isNaN(parseInt(row.p_id_document)) ? <Select
                    classNameParent="col-6 col-md-4mb-2"
                    icon="far fa-building"
                    text="Sucursal"
                    options={listBranchOffice.map(t => {
                        return {
                            label: t.name_branch_office,
                            value: t.id_branch_office
                        }
                    })}
                    value={branchOffice}
                    onChange={e => {
                        if (!row.p_id_customers) {
                            Helpers.toast.construct({ response: 'warning', message: 'Seleccionar cliente', toast })
                            return
                        }
                        Controllers.branch_office.get_branch_office_tax_shipping({
                            p_id_branch_office: e.value
                        }).then(res => {
                            setIvaTax(parseFloat(res.data.value_tax))
                            setRow({ ...row, p_id_branch_office: e.value, p_details: [] })
                        })
                    }}
                /> : <Input
                    text="Sucursal"
                    placeholder="Sucursal"
                    classNameParent="col-6 mb-2"
                    icon="far fa-building"
                    value={row.p_name_branch_office}
                    disabled
                />}
                <div className="col-12">
                    <hr />
                </div>
                {/* <Input
                text="Concepto del CDFI"
                placeholder="Concepto del CDFI"
                classNameParent="col-6 col-md-4 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.concept_cfdi_doc}
                onChange={e => setRow({ ...row, p_concept_cfdi_doc: e.currentTarget.value })}
                maxLength={100}
            /> */}
                <Input
                    text="Periodo"
                    placeholder="Periodo"
                    classNameParent="col-6 col-md-4 mb-2"
                    icon="far fa-comment-alt"
                    required
                    invalid="El campo es obligatorio."
                    defaultValue={row.p_code_period}
                    className="text-center"
                    disabled
                />
                <Select
                    classNameParent="col-6 col-md-4mb-2"
                    icon="far fa-building"
                    text="Forma de Pago"
                    options={listTypeFormatPay.map(t => {
                        return {
                            label: t.name_format_pay,
                            value: t.id_type_format_pay
                        }
                    })}
                    value={typeFormayPay}
                    disabled={!isNaN(parseInt(data.id_document))}
                    onChange={e => {
                        setRow({ ...row, p_id_type_format_pay: e.value })
                    }}
                />
                <Select
                    classNameParent="col-6 col-md-4mb-2"
                    icon="far fa-building"
                    text="Estado de Despacho"
                    options={listStatusDispatch.map(t => {
                        return {
                            label: t.name_dispatch,
                            value: t.id_dispatch
                        }
                    })}
                    value={statusDispatch}
                    onChange={e => {
                        setRow({ ...row, p_id_dispatch: e.value })
                    }}
                />
                <SwitchToggle
                    id="switch-has-invoice"
                    classNameParent="col-12 mt-2 align-self-center"
                    textCheck="Tiene Factura"
                    textUnChecked="No Tiene Factura"
                    defaultChecked={parseInt(data.has_invoice_doc) === 1}
                    onChange={e => setRow({ ...row, p_has_invoice_doc: e.currentTarget.checked ? 1 : 0 })}
                />
                <div className="mb-2 col-12 mt-2 d-flex justify-content-between">
                    {isNaN(parseInt(data.id_document)) ? <Button size="sm" onClick={handleShowModalProducts}>
                        <i className="fa fa-plus mr-1"></i>Agregar Producto
                </Button> : <div></div>}
                    <Button size="sm" disabled={existsStock.exists} variant={existsStock.exists ? 'outline-primary' : 'primary'} onClick={handleValidateStock}>
                        <i className="fa fa-check mr-1"></i>{existsStock.exists ? 'Stock Verificado' : 'Verificar Stock'}
                    </Button>
                </div>

                {!existsStock.exists && existsStock.details.products.length > 0 ? <div className="col-12">
                    <div className="alert alert-warning d-flex flex-column">
                        <span>{existsStock.details.message}</span>
                        <span className="mt-2 mb-2">Detalle</span>

                        {React.Children.toArray(existsStock.details.products.map(p => <>
                            <span>Producto: <strong>{p.key_product}</strong></span>
                            <span>Cantidad Solicitada: <strong>{p.qty_request}</strong></span>
                            <span>Stock: <strong>{p.qty}</strong></span>
                        </>))}
                    </div>
                </div> : ''}

                <div className="table-responsive col-12">
                    <table className="table table-sm table-hover table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>Clave</th>
                                <th>Descripción</th>
                                <th>Pack</th>
                                <th>Cantidad</th>
                                <th>Precio</th>
                                <th>Puntos</th>
                                <th>Valor Negocio</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {React.Children.toArray(row.p_details.map(r => <tr>
                                <td className="text-center">{r.key_product}</td>
                                <td>{r.name_product}</td>
                                <td align="center">
                                    <div className={`badge badge-${parseInt(r.pack_product) === 1 ? 'success' : 'secondary'}`}>
                                        {parseInt(r.pack_product) === 1 ? 'SI' : 'NO'}
                                    </div>
                                </td>
                                <td>
                                    <Input
                                        className="text-center"
                                        required
                                        type="number"
                                        min="1"
                                        max={parseInt(r.pack_product) === 0 ? r.exists_current_qty_product : ''}
                                        /* invalid={parseInt(r.pack_product) === 0 ? 'Stock: ' + r.exists_current_qty_product : ''} */
                                        step="1"
                                        value={!isNaN(parseInt(r.qty)) ? r.qty : ''}
                                        disabled={!isNaN(parseInt(data.id_document))}
                                        onChange={e => {
                                            setRow({
                                                ...row,
                                                p_details: row.p_details.map(rr => {
                                                    if (parseInt(r.id_product) === parseInt(rr.id_product)) {
                                                        rr.qty = e.currentTarget.value
                                                    }
                                                    return rr
                                                })
                                            })
                                            setExistsStock(notExistsStock)
                                        }}
                                        style={{
                                            minWidth: '100px'
                                        }}
                                    />
                                </td>
                                <td className="text-center">
                                    {parseFloat(r.price).toFixed(2)}
                                </td>
                                <td className="text-center">
                                    {parseFloat(r.point).toFixed(2)}
                                </td>
                                <td className="text-center">
                                    {parseFloat(r.value_business).toFixed(2)}
                                </td>
                                <td>
                                    {isNaN(parseInt(data.id_document)) ? <Button size="xs" variant="danger" onClick={() => {
                                        setRow({ ...row, p_details: row.p_details.filter(rr => parseInt(rr.id_product) !== parseInt(r.id_product)) })
                                    }}>
                                        <i className="fa fa-trash"></i>
                                    </Button> : ''}
                                </td>
                            </tr>))}
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td align="center">Subtotal</td>
                                <td align="center">{getSubtotal().subtotal}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td align="center">IVA</td>
                                <td align="center">{getSubtotal().iva} ({ivaTax * 100}%)</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td align="center">Total</td>
                                <td align="center">{getSubtotal().total}</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </FormCustom>
            <ProductList showModal={modalProducts} hideModal={handleHideModalProducts} nameSingle="Producto" namePlural="Productos" separator="el" idTypePrice={row.p_id_type_price} showProductsWithPrice added={row.p_details} idBranchOffice={row.p_id_branch_office} addItem={({ idProduct, keyProduct, nameProduct, priceProduct, pointProduct, valueBusinessProduct, qtyExists, packProduct }) => {
                let newDet = ({
                    id_product: idProduct,
                    key_product: keyProduct,
                    name_product: nameProduct,
                    price: priceProduct,
                    point: pointProduct,
                    value_business: valueBusinessProduct,
                    qty: '',
                    exists_current_qty_product: qtyExists,
                    pack_product: packProduct
                })
                setRow({ ...row, p_details: [...row.p_details, newDet] })
                setExistsStock(notExistsStock)
            }} />
            <CustomersList show={showModalCustomers} onHide={_ => setShowModalCustomers(false)} publicCustomers customersAdded={row.p_id_customers ? [{ id_customers: row.p_id_customers }] : []} onAddCustomers={({ idCustomers, idBranchOffice, nameBranchOffice, emailCustomers, pointCurrentCustomers, fullNameCustomers, idTypePrice }) => {
                Controllers.branch_office.get_branch_office_tax_shipping({
                    p_id_branch_office: idBranchOffice
                }).then(res => {
                    setIvaTax(parseFloat(res.data.value_tax))
                    setRow({
                        ...row,
                        p_id_customers: idCustomers,
                        p_id_branch_office: idBranchOffice,
                        p_full_name_customers: fullNameCustomers,
                        p_name_branch_office: nameBranchOffice,
                        p_email_customers: emailCustomers,
                        p_point_current_customers: pointCurrentCustomers,
                        p_id_type_price: idTypePrice,
                        p_details: []
                    })
                    setExistsStock(notExistsStock)
                    setShowModalCustomers(false)
                })
            }} />
            <Loading show={showLoading}>
                <Loading.Img src={Helpers.config.resourcesUrl({ url: '/images/dashboard/preloading.svg' })} alt="Preloading" />
            </Loading>
        </ReactPlaceholder>

    </>
}

SaleForm.defaultProps = {
    data: {
        id_document: '',
        number_invoice_doc: '',
        id_customers: '',
        id_type_price: '',
        full_name_customers: '',
        name_branch_office: '',
        email_customers: '',
        point_current_customers: '',
        has_invoice_doc: '0',
        concept_cfdi_doc: '',
        id_branch_office: '',
        id_period: '',
        code_period: '',
        details: [],
        point_sale: 0,
        id_type_format_pay: '',
        id_dispatch: ''
    },
    onSubmit: () => { },
    loadSubmit: false
}

export default SaleForm;