import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Card from 'react-bootstrap/Card';
import Controllers from '../../Api/Controllers';
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
import SweetAlert from '../../Components/SweetAlert/SweetAlert'
import { FormCustom, Input } from '../../Components/Form/Form';
import ReactPlaceholder from 'react-placeholder/lib';

const ProductPrice = ({ nameSingle, namePlural, idProduct, onSuccess }) => {
    const [rows, setRows] = useState([])
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [ready, setReady] = useState(false)

    useEffect(() => {
        Controllers.product_price.get_product_price({
            p_id_product: idProduct
        }).then(res => {
            setRows(res.data)
            setReady(true)
        }).catch(req => Helpers.promise.catch({ req, toast }))
    }, [])

    const handleInsertUpdate = ({ data }) => {
        setLoadSubmit(true)
        Controllers.product_price.product_price_insert_update(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                onSuccess()
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setLoadSubmit(false)
        })
    }

    const dataSubmit = useCallback(() => {
        return {
            p_id_product: idProduct,
            p_price: rows.map(r => {
                return {
                    p_id_type_price: r.id_type_price,
                    p_price: r.price,
                    p_point: r.point,
                    p_value_business: r.value_business,
                }
            })
        }
    }, [idProduct, rows])

    return <ReactPlaceholder rows={5} ready={ready}>
        <Card>
            <Card.Header className="justify-content-between">
                <span>Listado De {namePlural}</span>
            </Card.Header>
            <Card.Body>
                <FormCustom dataSubmit={dataSubmit()} onSubmit={handleInsertUpdate} loadSubmit={loadSubmit}>
                    <div className="table-responsive">
                        <table className="table table-sm table-hover table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Nombre</th>
                                    <th>Precio</th>
                                    <th>Puntos</th>
                                    <th>Valor Negocio</th>
                                </tr>
                            </thead>
                            <tbody>
                                {React.Children.toArray(rows.map(r => <tr>
                                    <td align="center">{r.id_type_price}</td>
                                    <td>{r.name_price}</td>
                                    <td align="center">
                                        <Input
                                            className="text-center"
                                            required
                                            type="number"
                                            min="0"
                                            step="0.01"
                                            value={!isNaN(parseInt(r.price)) ? r.price : ''}
                                            onChange={e => {
                                                setRows(rows.map(rr => {
                                                    if (parseInt(r.id_type_price) === parseInt(rr.id_type_price)) {
                                                        rr.price = e.currentTarget.value
                                                    }
                                                    return rr
                                                }))
                                            }}
                                            style={{
                                                minWidth: '100px'
                                            }}
                                        />
                                    </td>
                                    <td align="center">
                                        <Input
                                            className="text-center"
                                            required
                                            type="number"
                                            min="0"
                                            step="0.10"
                                            value={!isNaN(parseInt(r.point)) ? r.point : ''}
                                            onChange={e => {
                                                setRows(rows.map(rr => {
                                                    if (parseInt(r.id_type_price) === parseInt(rr.id_type_price)) {
                                                        rr.point = e.currentTarget.value
                                                    }
                                                    return rr
                                                }))
                                            }}
                                            style={{
                                                minWidth: '100px'
                                            }}
                                        />
                                    </td>
                                    <td align="center">
                                        <Input
                                            className="text-center"
                                            required
                                            type="number"
                                            min="0"
                                            step="0.10"
                                            value={!isNaN(parseInt(r.value_business)) ? r.value_business : ''}
                                            onChange={e => {
                                                setRows(rows.map(rr => {
                                                    if (parseInt(r.id_type_price) === parseInt(rr.id_type_price)) {
                                                        rr.value_business = e.currentTarget.value
                                                    }
                                                    return rr
                                                }))
                                            }}
                                            style={{
                                                minWidth: '100px'
                                            }}
                                        />
                                    </td>
                                </tr>))}
                            </tbody>
                        </table>
                    </div>
                </FormCustom>
            </Card.Body>
        </Card>
    </ReactPlaceholder>
}

export default ProductPrice