import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import Helpers from '../../Components/Helpers/Helpers';
import { toast } from 'react-toastify'
import { FormCustom, Input } from '../../Components/Form/Form';
import Button from 'react-bootstrap/Button'
import Loading from '../../Components/Layout/Styled/Loading'

const ReporteRed = _ => {
    const [isProcessing, setIsProccesing] = useState(false)
    const [rows, setRows] = useState({})

    const dtRows = useMemo(() => <ReactDataTableBootstrap
        head={[
            [
                { name: 'id_customers', text: 'ID', align: 'center', sort: false },
                { name: 'id_upline', text: 'Upline', align: 'center', sort: false },
                { name: 'id_sponsor', text: 'Sponsor', align: 'center', sort: false },
                { name: 'full_name', text: 'Nombres', sort: false },
                { name: 'nivel', text: 'Nivel', align: 'center', sort: false },
                { name: 'name_plan', text: 'Rango', align: 'center', sort: false },
                { name: 'date_registration', text: 'Fecha de Ingreso', align: 'center', sort: false },
                { name: 'point_current_customers', text: 'Puntos', align: 'center', sort: false },
                { name: 'point_business_customers', text: 'Valor Negocio', align: 'center', sort: false },
                { name: 'id_status', text: 'Estatus', align: 'center', sort: false, render: r => <div className={'badge badge-' + (parseInt(r.id_status) === 1 ? 'success' : 'danger')}>
                    {parseInt(r.id_status) === 1 ? 'Activo' : 'De Baja'}
                </div> },
                { name: 'key_branch_office', text: 'Sucursal', align: 'center', sort: false },
                { name: 'code_country', text: 'País', align: 'center', sort: false },
            ],
        ]}
        rows={rows?.data ? rows.data : []}
        pageLength={100}
        isProcessing={isProcessing}
        noSort
    />, [rows, isProcessing])

    const [data, setData] = useState({
        p_id_customers: '',
        p_full_name_customers: ''
    })
    const [showLoading, setShowLoading] = useState(false)

    return <>
        <Card>
            <Card.Header className="justify-content-between">
                <span>Descendencia de Red</span>
            </Card.Header>
            <Card.Body>
                <FormCustom viewSubmit={false} onSubmit={_ => {
                    setRows({})
                    setIsProccesing(true)
                    setShowLoading(true)
                    Controllers.red.get_find_red({
                        p_id_customers: data.p_id_customers
                    }).then(res => {
                        if (res.response !== 'success') {
                            Helpers.toast.construct({ ...res, toast })
                            return
                        }
                        setData({ p_id_customers: res.data.id_customers, p_full_name_customers: res.data.name_customers })
                        setRows(res.data)
                    }).catch(req => Helpers.promise.catch({ req, toast }))
                    .finally(() => {
                        setIsProccesing(false)
                        setShowLoading(false)
                    })
                }}>
                    <Input
                        classNameParent="col-12 mb-2"
                        type="number"
                        pattern="[0-9]{1,9}"
                        icon="fa fa-user"
                        placeholder="Id Cliente"
                        text="Id Cliente"
                        required
                        onChange={e => {
                            setData({ ...data, p_id_customers: e.currentTarget.value, p_full_name_customers: '' })
                            setRows([])
                        }}
                        append={[
                            <Button type="submit">
                                <i className="fa fa-search"></i>
                            </Button>
                        ]}
                        value={data.p_id_customers}
                    />
                    <Input
                        classNameParent="col-12 mb-4"
                        icon="fa fa-user"
                        placeholder="Nombre Cliente"
                        text="Nombre Cliente"
                        required
                        disabled
                        value={data.p_full_name_customers}
                    />
                </FormCustom>
                { dtRows }
            </Card.Body>
        </Card>
        <Loading show={showLoading}>
            <Loading.Img src={Helpers.config.resourcesUrl({ url: '/images/dashboard/preloading.svg' })} alt="Preloading" />
        </Loading>
    </>
}

export default ReporteRed