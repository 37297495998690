import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Card from 'react-bootstrap/Card';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import Helpers from '../../Components/Helpers/Helpers';
import { toast } from 'react-toastify'
import { FormCustom,  Select } from '../../Components/Form/Form';
import ReactPlaceholder from 'react-placeholder'
import Button from 'react-bootstrap/esm/Button';

const PointPeriod = _ => {
    const [ready, setReady] = useState(false)
    const [isProcessing, setIsProccesing] = useState(false)
    const [periodSelected, setPeriodSelected] = useState('')
    const [rows, setRows] = useState({
        money: [],
        details: []
    })
    const [period, setPeriod] = useState([])

    useEffect(() => {
        Controllers.period.get_period().then(res => {
            setPeriod(res.data)
            setReady(true)
        })
    }, [])

    const handleSubmit = _ => {
        setIsProccesing(true)
        Controllers.report.get_report_commissions_period({
            p_id_period: periodSelected
        }).then(res => {
            setRows(res.data)
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setIsProccesing(false)
        })
    }

    const moneyList = useCallback(() => {
        let m = []
        for (const key in rows.money) {
            m.push(<h4><strong>{key}</strong> {rows.money[key]}</h4>)
        }
        return m
    }, [rows])

    const dtRows = useMemo(() => <ReactDataTableBootstrap
        head={[
            [
                { name: 'key_branch_office', text: 'Sucursal', align: 'center', sort: false },
                { name: 'id_customers', text: 'ID', align: 'center', sort: false },
                { name: 'full_name', text: 'Nombres', sort: false },
                { name: 'name_plan', text: 'Rango', sort: false },
                { name: 'subtotal_earnings', text: 'Ganancia', align: 'center', sort: false, render: r => '$ ' + r.subtotal_earnings },
                { name: 'isr', text: 'ISR', align: 'center', sort: false, render: r => '$ ' + r.isr },
                { name: 'total', text: 'Total', align: 'center', sort: false, render: r => '$ ' + r.total },
                { name: 'code_money', text: 'Moneda', align: 'center', sort: false },
            ],
        ]}
        rows={rows.details}
        pageLength={100}
        isProcessing={isProcessing}
        noSort
    />, [rows, isProcessing])

    return <>
        <ReactPlaceholder rows={10} showLoadingAnimation ready={ready}>
            <Card>
                <Card.Header className="justify-content-between">
                    <span>Comisiones por Periódo</span>
                </Card.Header>
                <Card.Body>
                    <FormCustom viewSubmit={false}>
                        <Select
                            text="Periódo"
                            classNameParent="col-12 mb-2"
                            options={period.map(p => {
                                return {
                                    label: p.name_period,
                                    value: p.id_period
                                }
                            })}
                            onChange={e => setPeriodSelected(e.value)}
                            append={[
                                <Button onClick={handleSubmit}>
                                    <i className="fa fa-search"></i>
                                </Button>
                            ]}
                        />
                    </FormCustom>
                    { dtRows }
                </Card.Body>
            </Card>
            {isProcessing ? '' : <div className="d-flex justify-content-center mt-4">
                <div className="alert alert-success text-center">
                    <h5>Total de Comisiones</h5>
                    {moneyList()}
                </div>
            </div>}
        </ReactPlaceholder>
    </>
}

export default PointPeriod