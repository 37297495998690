import React, { useState } from 'react'
import { Input, FormCustom } from '../../Components/Form/Form'

const LimitForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_limit: data.id_limit,
        p_min_limit: data.min_limit,
        p_max_limit: data.max_limit,
        p_quota_limit: data.quota_limit,
        p_percentage_limit: data.percentage_limit
    })

    return <FormCustom dataSubmit={row} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
        <Input
            text="Mínimo"
            placeholder="Mínimo"
            classNameParent="col-12 mb-2"
            icon="fas fa-sort-numeric-up-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.min_limit}
            onChange={e => setRow({ ...row, p_min_limit: e.currentTarget.value })}
            type="number"
            min="0"
            step="0.01"
        />
        <Input
            text="Máximo"
            placeholder="Máximo"
            classNameParent="col-12 mb-2"
            icon="fas fa-sort-numeric-up-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.max_limit}
            onChange={e => setRow({ ...row, p_max_limit: e.currentTarget.value })}
            type="number"
            min="0"
            step="0.01"
        />
        <Input
            text="Cuota"
            placeholder="Cuota"
            classNameParent="col-12 mb-2"
            icon="fas fa-sort-numeric-up-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.quota_limit}
            onChange={e => setRow({ ...row, p_quota_limit: e.currentTarget.value })}
            type="number"
            min="0"
            step="0.01"
        />
        <Input
            text="Porcentaje"
            placeholder="Porcentaje"
            classNameParent="col-12 mb-2"
            icon="fas fa-sort-numeric-up-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.percentage_limit}
            onChange={e => setRow({ ...row, p_percentage_limit: e.currentTarget.value })}
            type="number"
            min="0"
            step="0.0001"
        />
    </FormCustom>
}

LimitForm.defaultProps = {
    data: {
        id_limit: '',
        min_limit: '',
        max_limit: '',
        quota_limit: '',
        percentage_limit: '',
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default LimitForm;