import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Helpers from '../../Components/Helpers/Helpers';
import Customers from './Customers';

const CustomersList = ({ show, onHide, publicCustomers, onAddCustomers, customersAdded }) => {

    return <Modal show={show} onHide={onHide} backdrop="static" size="xl">
        <Modal.Header closeButton>
            <Modal.Title as="div">Seleccionar Productos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Customers nameSingle={Helpers.string.toCamelCase('Cliente')} namePlural={Helpers.string.toCamelCase('Clientes')} separator={'el'.toLowerCase()} publicCustomers={publicCustomers} onAddCustomers={onAddCustomers} customersAdded={customersAdded} />
        </Modal.Body>
    </Modal>
}

CustomersList.defaultProps = {
    publicCustomers: false,
    onAddCustomers: () => {},
    customersAdded: []
}

export default CustomersList