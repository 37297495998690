import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/esm/Card'
import Controllers from '../../Api/Controllers'
import ReactPlaceholder from 'react-placeholder/lib'
import Helpers from '../../Components/Helpers/Helpers'
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap'
import Modal from 'react-bootstrap/Modal'
import { FormCustom, Select } from '../../Components/Form/Form'
import { toast } from 'react-toastify'

const ClosePeriod = _ => {
    const [ready, setReady] = useState(false)
    const [rowsPeriod, setRowsPeriod] = useState([])
    const [loadSubmit, setLoadSubmit] = useState(false)
    const [row, setRow] = useState({
        p_id_period: ''
    })

    useEffect(() => {
        if (!ready) {
            Controllers.period.get_period().then(res => {
                setRowsPeriod(res.data)
                setReady(true)
            })
        }
    }, [ready])

    const periodSelected = useMemo(() => {
        if (!row.p_id_period) {
            return
        }

        let finded = rowsPeriod.find(t => parseInt(t.id_period) === parseInt(row.p_id_period))

        if (!finded) {
            return
        }

        return {
            label: finded.name_period,
            value: finded.id_period
        }
    }, [row.p_id_period, rowsPeriod])

    const onSubmit = ({ data }) => {
        setLoadSubmit(true)
        Controllers.period.get_plan_calculate_range(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setReady(false)
                setRow({ ...row, p_id_period: '' })
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setLoadSubmit(false)
        })
    }


    return <>
        <Card className="mb-4">
            <Card.Header>
                Cerrar Periodo
            </Card.Header>
            <Card.Body>
                <ReactPlaceholder ready={ready} showLoadingAnimation rows={5}>
                    <FormCustom loadSubmit={loadSubmit} dataSubmit={row} onSubmit={({ data }) => {
                        if (!row.p_id_period) {
                            Helpers.toast.construct({ response: 'warning', message: 'Seleccionar Periodo', toast })
                            return
                        }

                        onSubmit({ data })
                    }} textButton="Procesar">
                        <Select
                            text="Periodo"
                            options={rowsPeriod.map(b => {
                                return {
                                    label: b.name_period + (parseInt(b.finish_period) === 1 ? ' - CERRADO' : ''),
                                    value: b.id_period,
                                    isDisabled: parseInt(b.finish_period) === 1
                                }
                            })}
                            value={periodSelected}
                            onChange={e => setRow({ ...row, p_id_period: e.value })}
                            classNameParent="col-12 mb-2"
                        />
                    </FormCustom>
                </ReactPlaceholder>
            </Card.Body>
        </Card>
    </>
}

export default ClosePeriod