import React, { useEffect, useState, useMemo } from 'react'
import ReactPlaceholder from 'react-placeholder/lib'
import Controllers from '../../Api/Controllers'
import { Input, FormCustom, Select } from '../../Components/Form/Form'

const ProductComponentForm = ({ data, onSubmit, disablebSubmit, loadSubmit, idProduct }) => {
    const [row, setRow] = useState({
        p_id_component: data.id_component,
        p_id_product: idProduct,
        p_qty: data.qty
    })

    const [rowsComponent, setRowsComponent] = useState([])
    const [ready, setReady] = useState(false)

    useEffect(() => {
        Controllers.product.get_product().then(res => {
            setRowsComponent(res.data)
            setReady(true)
        })
    }, [])

    const component = useMemo(() => {
        if (!row.p_id_component) {
            return
        }

        let finded = rowsComponent.find(t => parseInt(t.id_product) === parseInt(row.p_id_component))

        if (!finded) {
            return
        }

        return {
            label: `${finded.id_product} - ${finded.name_product}`,
            value: finded.id_product
        }
    }, [row.p_id_component, rowsComponent])

    return <ReactPlaceholder ready={ready} rows={7}>
        <FormCustom dataSubmit={row} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
            <Select
                classNameParent="col-12 mb-2"
                icon="fas fa-list"
                text="Component"
                options={rowsComponent.filter(t => parseInt(t.id_product) !== parseInt(idProduct) && parseInt(t.pack_product) === 0).map(t => {
                    return {
                        label: `${t.id_product} - ${t.name_product}`,
                        value: t.id_product
                    }
                })}
                value={component}
                onChange={e => setRow({ ...row, p_id_component: e.value })}
                disabled={data.id_component !== ''}
            />
            <Input
                text="Cantidad"
                placeholder="Cantidad"
                classNameParent="col-12 mb-2"
                icon="far fa-comment-alt"
                required
                invalid="El campo es obligatorio."
                defaultValue={data.qty}
                onChange={e => setRow({ ...row, p_qty: e.currentTarget.value })}
                type="number"
                min="1"
                step="1"
            />
        </FormCustom>
    </ReactPlaceholder>
}

ProductComponentForm.defaultProps = {
    data: {
        id_component: '',
        qty: ''
    },
    onSubmit: () => {},
    loadSubmit: false,
    idProduct: ''
}

export default ProductComponentForm;