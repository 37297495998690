import React from 'react'
import TextForm from './TextForm'

const ImagesSystem = _ => {
    return <div className="form-row">
        <div className="col-12 col-md-6">
            <TextForm idText="1" />
        </div>
        <div className="col-12 col-md-6">
            <TextForm idText="2" />
        </div>
        <div className="col-12 col-md-6">
            <TextForm idText="3" fullImage />
        </div>
        <div className="col-12 col-md-6">
            <TextForm idText="4" />
        </div>
    </div>
}

export default ImagesSystem