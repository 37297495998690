import React, { useState } from 'react'
import { Input, FormCustom } from '../../Components/Form/Form'

const CountryForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_country: data.id_country,
        p_name_country: data.name_country,
        p_code_country: data.code_country
    })

    return <FormCustom dataSubmit={row} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
        <Input
            text="Descripción"
            placeholder="Descripción"
            classNameParent="col-12 mb-2"
            icon="far fa-comment-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.name_country}
            onChange={e => setRow({ ...row, p_name_country: e.currentTarget.value })}
            maxLength={150}
        />
        <Input
            text="Código"
            placeholder="Código"
            classNameParent="col-12 mb-2"
            icon="fas fa-code"
            required
            invalid="Error en formato. Ej: MX, USA"
            defaultValue={data.code_country}
            onChange={e => setRow({ ...row, p_code_country: e.currentTarget.value })}
            maxLength={5}
        />

    </FormCustom>
}

CountryForm.defaultProps = {
    data: {
        id_country: '',
        name_country: '',
        code_country: ''
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default CountryForm;