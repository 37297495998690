import styled from 'styled-components'
import { primaryGradient, widthResize, widthFull } from '../Resources/Theme'
import Helpers from '../../Helpers/Helpers'

const PageWrapper = styled.div`
  margin-left: ${props => props.resize ? widthResize : widthFull};
  width: calc(100% - ${props => props.resize ? widthResize : widthFull});
  transition: .5s;

  @media (max-width: 992px) {
    width: 100%;
    margin-left: 0;
  }
`
PageWrapper.BgTop = styled.div`
  top: 0;
  padding: 0 20px;
  padding-top: 50px;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0) url("${Helpers.config.resourcesUrl({ url: '/images/dashboard/background-banner-dashboard.png?' + Date.now() })}") center top;

  background-size: contain;
  background-repeat: no-repeat;

  @media(max-width: 480px) {
   max-height: 155px;
   padding-top: 0
  }
`
PageWrapper.Title = styled.h4`
  margin-bottom: 0;
  align-self: center;
  font-weight: bolder;
  /* text-shadow: 10px 10px 12px #fff, -10px -10px 12px #fff; */


  @media (max-width: 992px) {
    font-size: 1.1rem;
  }
`
PageWrapper.Body = styled.div`
  position: relative;
  top: -55px;
  padding: 0 20px;
  min-height: calc(100vh - 221px);
`

/* Start BreadCrumb */
PageWrapper.BreadCrumb = styled.div`
  font-weight: lighter;
  display: flex;
  align-self: center;
  
  @media (max-width: 992px) {
    display: none;
  }
`
PageWrapper.BreadCrumb.Title = styled.span`
  margin-right: .7rem;
  display: flex;
  font-weight: ${props => props.active ? 'bold' : ''};
  align-self: center;
`
PageWrapper.BreadCrumb.Icon = styled.div`
  align-self: center;
  transform: rotate(135deg);
  content: '';
  width: 5px;
  height: 5px;
  border-width: 1px 0 0 1px;
  border-style: solid;
  margin-right: .7rem;
`
/* End BreadCrumb */

export default PageWrapper