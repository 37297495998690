import React, { useState } from 'react'
import { Input, FormCustom } from '../../Components/Form/Form'

const TypeFormatPayForm = ({ data, onSubmit, disablebSubmit, loadSubmit }) => {
    const [row, setRow] = useState({
        p_id_type_format_pay: data.id_type_format_pay,
        p_name_format_pay: data.name_format_pay
    })

    return <FormCustom dataSubmit={row} onSubmit={onSubmit} disablebSubmit={disablebSubmit} loadSubmit={loadSubmit}>
        <Input
            text="Descripción"
            placeholder="Descripción"
            classNameParent="col-12 mb-2"
            icon="far fa-comment-alt"
            required
            invalid="El campo es obligatorio."
            defaultValue={data.name_format_pay}
            onChange={e => setRow({ ...row, p_name_format_pay: e.currentTarget.value })}
            maxLength={150}
        />
    </FormCustom>
}

TypeFormatPayForm.defaultProps = {
    data: {
        id_type_format_pay: '',
        name_format_pay: ''
    },
    onSubmit: () => {},
    loadSubmit: false
}

export default TypeFormatPayForm;