import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import { toast } from 'react-toastify';
import Helpers from '../../Components/Helpers/Helpers';
import ProductForm from './ProductForm';

const ProductList = ({ nameSingle, namePlural, separator, showModal, hideModal, addItem, idBranchOffice, added, showButtonNewProduct, showProductsWithPrice, idTypePrice, viewPack }) => {
    const [rows, setRows] = useState([])
    const [isProcessing, setIsProcessing] = useState(true)

    useEffect(() => {
        if (showModal) {
            setIsProcessing(true)
        }
    }, [showModal])

    useEffect(() => {
        let paramsCompleted = false
        if (showProductsWithPrice) {
            if (idTypePrice && idBranchOffice) {
                paramsCompleted = true
            }
        } else {
            if (idBranchOffice) {
                paramsCompleted = true
            }
        }
        if (isProcessing && paramsCompleted) {
            Controllers.product[showProductsWithPrice ? 'get_product_type_price' : 'get_product']({
                p_id_branch_office: idBranchOffice,
                p_id_type_price: idTypePrice
            }).then(res => {
                setRows(res.data)
                setIsProcessing(false)
            }).catch(req => Helpers.promise.catch({ req, toast }))
        }
    }, [isProcessing, idBranchOffice, idTypePrice])

    const handleClickAdd = e => {
        let { idProduct, keyProduct, nameProduct, qtyExists, priceProduct, pointProduct, valueBusinessProduct, packProduct } = e.currentTarget.dataset

        addItem({
            idProduct, keyProduct, nameProduct, qtyExists, priceProduct, pointProduct, valueBusinessProduct, packProduct 
        })
    }

    const dtRows = useMemo(() => <ReactDataTableBootstrap
        head={[
            [
                { name: 'actions', align: 'center', text: <i className="fa fa-cog"></i>, render: r => {
                    let isAdded = added.some(a => parseInt(a.id_product) === parseInt(r.id_product))
                    let productWithouStock = parseInt(r.exists_current_qty_product) <= 0 && parseInt(r.pack_product) === 0
                    return <Button variant={isAdded ? 'success' : (productWithouStock && idTypePrice ? 'danger' : 'dark')} onClick={isAdded || (productWithouStock && idTypePrice) ? () => {} : handleClickAdd} size="xs" data-id-product={r.id_product} data-name-product={r.name_product} data-qty-exists={r.exists_current_qty_product} data-pack-product={r.pack_product} data-key-product={r.key_product} data-price-product={r.price_product} data-point-product={r.point_product} data-value-business-product={r.value_business_product}>
                        {productWithouStock && idTypePrice ? 'Sin Stock' : <i className={isAdded ? 'fa fa-check' : 'fa fa-plus'}></i>}
                    </Button>
                } },
                { name: 'id_product', text: 'ID', align: 'center' },
                { name: 'key_product', text: 'Clave', align: 'center' },
                { name: 'name_product', text: 'Nombre' },
                { name: 'pack_product', text: 'Paquete', render: r => {
                    return <div className={`badge badge-${parseInt(r.pack_product) === 1 ? 'success' : 'secondary'}`}>
                        {parseInt(r.pack_product) === 1 ? 'SI' : 'NO'}
                    </div>
                }, align: 'center' },
                { name: 'name_clasification', text: 'Clasificación', align: 'center' },
                { name: 'file_photo', text: 'Foto', render: r => {
                    return r.file_photo ? <a href={Helpers.config.resourcesUrl({ url: r.file_photo })} target="_blank">
                    <img src={Helpers.config.resourcesUrl({ url: r.file_photo })} width="100" />
                </a> : <div className="badge badge-warning">
                    Sin Foto
                </div>
                }, align: 'center' },
                /* { name: 'iva_product', text: 'IVA', align: 'center' }, */
                { name: 'tax_1', text: 'Tax', align: 'center' }
            ],
        ]}
        columnOrder="1"
        orderDirection="asc"
        rows={rows.map(r => {
            return {
                ...r, actions: ''
            }
        }).filter(r => {
            if (!viewPack) {
                if (parseInt(r.pack_product) === 1) {
                    return false
                }
            }

            return r
        })}
        isProcessing={isProcessing}
    />, [rows, isProcessing, added])

    const [loadSubmit, setLoadSubmit] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [modalShowProducts, setModalShowProducts] = useState(false)
    
    const handleGet = _ => {
        setModalTitle(`Registrar ${nameSingle}`)
        setModalShowProducts(true);
    }

    const handleHideModalForm = () => {
        setModalShowProducts(false)
        setLoadSubmit(false)
    }

    const handleInsertUpdate = ({ data }) => {
        setLoadSubmit(true)
        Controllers.product.product_insert_update(data).then(res => {
            Helpers.toast.construct({ ...res, toast })
            if (res.response === 'success') {
                setModalShowProducts(false)
            }
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setLoadSubmit(false)
            setIsProcessing(true)
        })
    }

    return <>
        <Modal show={showModal} onHide={hideModal} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title as="div">
                    <span>Listado De {namePlural}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card>
                    <Card.Header className="justify-content-between">
                        <span>Listado De {namePlural}</span>
                        {showButtonNewProduct ? <Button size="sm" onClick={handleGet}>
                            <i className="fa fa-plus mr-1"></i> Registrar {nameSingle}
                        </Button> : ''}
                    </Card.Header>
                    <Card.Body>
                        { dtRows }
                    </Card.Body>
                </Card>
            </Modal.Body>
        </Modal>
        <Modal show={modalShowProducts} onHide={handleHideModalForm} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title as="div">{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ProductForm onSubmit={handleInsertUpdate} loadSubmit={loadSubmit} />
            </Modal.Body>
        </Modal>
    </>
}

ProductList.defaultProps = {
    showButtonNewProduct: true,
    showProductsWithPrice: false,
    viewPack: true
}

export default ProductList