import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import Helpers from '../../Components/Helpers/Helpers';
import { toast } from 'react-toastify'
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';

const SaleTotalBranchOffice = _ => {
    const [isProcessing, setIsProccesing] = useState(true)
    const [rows, setRows] = useState([])

    useEffect(() => {
        setIsProccesing(true)
        Controllers.sale.get_sale_total().then(res => {
            setRows(res.data)
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setIsProccesing(false)
        })
    }, [])

    const dtRows = useMemo(() => <ReactDataTableBootstrap
        head={[
            [
                { name: 'key_branch_office', text: 'Sucursal', align: 'center' },
                { name: 'total_doc', text: 'Total Neto', align: 'center' },
                { name: 'iva_doc', text: 'IVA', align: 'center' },
                { name: 'total_mxn', text: 'Total MX', align: 'center' },
                { name: 'total_usd', text: 'Total DLL', align: 'center' },
                { name: 'code_period', text: 'Periódo', align: 'center' },
            ],
        ]}
        rows={rows}
        pageLength={100}
        isProcessing={isProcessing}
    />, [rows, isProcessing])

    return <>
        <Card>
            <Card.Header className="justify-content-between">
                <span>Ventas Totales por Sucursal</span>
            </Card.Header>
            <Card.Body>
                { dtRows }
            </Card.Body>
        </Card>
    </>
}

export default SaleTotalBranchOffice