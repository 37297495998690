import React, { useState, useEffect, useMemo } from 'react'
import Card from 'react-bootstrap/Card';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import Helpers from '../../Components/Helpers/Helpers';
import { toast } from 'react-toastify'

const Red = _ => {
    const [isProcessing, setIsProccesing] = useState(true)
    const [rows, setRows] = useState([])

    useEffect(() => {
        setIsProccesing(true)
        Controllers.red.get_my_red().then(res => {
            setRows(res.data)
        }).catch(req => Helpers.promise.catch({ req, toast }))
        .finally(() => {
            setIsProccesing(false)
        })
    }, [])

    const dtRows = useMemo(() => <ReactDataTableBootstrap
        head={[
            [
                { name: 'id_customers', text: 'ID', align: 'center', sort: false },
                { name: 'id_upline', text: 'Upline', align: 'center', sort: false },
                { name: 'id_sponsor', text: 'Sponsor', align: 'center', sort: false },
                { name: 'full_name', text: 'Nombres', sort: false },
                { name: 'nivel', text: 'Nivel', align: 'center', sort: false },
                { name: 'name_plan', text: 'Rango', align: 'center', sort: false },
                { name: 'date_registration', text: 'Fecha de Ingreso', align: 'center', sort: false },
                { name: 'point_current_customers', text: 'Puntos', align: 'center', sort: false },
                { name: 'point_business_customers', text: 'Valor Negocio', align: 'center', sort: false },
                { name: 'id_status', text: 'Estatus', align: 'center', sort: false, render: r => <div className={'badge badge-' + (parseInt(r.id_status) === 1 ? 'success' : 'danger')}>
                    {parseInt(r.id_status) === 1 ? 'Activo' : 'De Baja'}
                </div> },
                { name: 'key_branch_office', text: 'Sucursal', align: 'center', sort: false },
                { name: 'code_country', text: 'País', align: 'center', sort: false },
            ],
        ]}
        rows={rows}
        pageLength={100}
        isProcessing={isProcessing}
        noSort
    />, [rows, isProcessing])

    return <>
        <Card>
            <Card.Header className="justify-content-between">
                <span>Descendencia de Red</span>
            </Card.Header>
            <Card.Body>
                { dtRows }
            </Card.Body>
        </Card>
    </>
}

export default Red