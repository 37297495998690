import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'

const styles = {
  groupStyles: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  groupBadgeStyles: {
    backgroundColor: 'red',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  },
  styles: {
    container: base => ({
      ...base,
      flex: 1
    }),
    control: (provided) => ({
      ...provided,
      minHeight: '29.55px',
      height: '29.55px'
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 8px',
      marginTop: '-1px'
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      maxHeight: '29.55px'
    })
  }
}

const Select = ({ classNameParent, text, size, icon, onChange, value, defaultValue, className, options, append, disabled, ...props }) => {
  const propValue = useCallback(() => {
    return defaultValue ? { defaultValue } : { value }
  }, [defaultValue, value])
  
  return (<div className={classNameParent}>
    <small className="text-muted">{text}</small>
    <div className={'input-group input-group-' + size}>
      {icon ? <div className="input-group-prepend">
        <div className="input-group-text"><i className={icon}></i></div>
      </div> : ''}
      <ReactSelect
        styles={styles.styles}
        options={options}
        {...propValue()}
        onChange={e => onChange(e)}
        formatGroupLabel={data => {
          return <div style={styles.groupStyles}>
            <span>{data.label}</span>
            <span style={styles.groupBadgeStyles}>{data.options.length}</span>
          </div>
        }}
        placeholder="Seleccionar..."
        {...props}
        isDisabled={disabled}
      />
      <div className="input-group-append">
        {React.Children.toArray(append)}
      </div>
    </div>
  </div>)
}

Select.defaultProps = {
  size: 'sm',
  icon: '',
  classNameParent: 'col-12',
  required: false,
  append: [],
  options: [],
  onChange: () => {},
  disabled: false
}
Select.propTypes = {
  classNameParent: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg']),
  icon: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array,
  append: PropTypes.array,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  required: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}

export default Select