import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Card from 'react-bootstrap/Card';
import ReactDataTableBootstrap from '../../Components/ReactDataTableBootstrap/ReactDataTableBootstrap';
import Controllers from '../../Api/Controllers';
import Helpers from '../../Components/Helpers/Helpers';
import { toast } from 'react-toastify'
import { FormCustom, Select } from '../../Components/Form/Form';
import ReactPlaceholder from 'react-placeholder'
import Button from 'react-bootstrap/esm/Button';

const CommisonsPeriod = _ => {
    const [ready, setReady] = useState(false)
    const [isProcessing, setIsProccesing] = useState(false)
    const [periodSelected, setPeriodSelected] = useState('')
    const [rows, setRows] = useState([])
    const [period, setPeriod] = useState([])

    useEffect(() => {
        Controllers.period.get_period().then(res => {
            setPeriod(res.data)
            setReady(true)
        })
    }, [])

    const handleSubmit = _ => {
        setIsProccesing(true)
        Controllers.report.get_report_point_period({
            p_id_period: periodSelected
        }).then(res => {
            setRows(res.data)
        }).catch(req => Helpers.promise.catch({ req, toast }))
            .finally(() => {
                setIsProccesing(false)
            })
    }

    const dtRows = useMemo(() => {
        return <ReactDataTableBootstrap
            head={[
                [
                    { name: 'key_branch_office', text: 'Sucursal', align: 'center', sort: false },
                    { name: 'id_customers', text: 'ID', align: 'center', sort: false },
                    { name: 'full_name_customers', text: 'Nombres', sort: false },
                    {
                        name: 'point_current_customers', text: 'Puntos', align: 'center', sort: false, render: r => {
                            return <div className={'badge badge-' + (parseInt(r.point_current_customers) >= Helpers.config.pointMinRange ? 'success' : (parseInt(r.point_current_customers) > 0 ? 'warning' : 'danger') )}>
                                {r.point_current_customers}
                            </div>
                        }
                    },
                    { name: 'point_business_customers', text: 'Valor Negocio', align: 'center', sort: false },
                ],
            ]}
            rows={rows}
            pageLength={100}
            isProcessing={isProcessing}
            noSort
        />
    }, [rows, isProcessing])

    return <>
        <ReactPlaceholder rows={10} showLoadingAnimation ready={ready}>
            <Card>
                <Card.Header className="justify-content-between">
                    <span>Comisiones por Periódo</span>
                </Card.Header>
                <Card.Body>
                    <FormCustom viewSubmit={false}>
                        <Select
                            text="Periódo"
                            classNameParent="col-12 mb-2"
                            options={period.map(p => {
                                return {
                                    label: p.name_period,
                                    value: p.id_period
                                }
                            })}
                            onChange={e => setPeriodSelected(e.value)}
                            append={[
                                <Button onClick={handleSubmit}>
                                    <i className="fa fa-search"></i>
                                </Button>
                            ]}
                        />
                    </FormCustom>
                    {dtRows}
                </Card.Body>
            </Card>
        </ReactPlaceholder>
    </>
}

export default CommisonsPeriod